





































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DraggableIcon from '../GroupLevelAttributes/DraggableIcon.vue';
import { clone } from '../../../../jbi-shared/util/group-level-attributes.util';
import {
  GroupLevelAttributeListType,
  GroupLevelAttributeValue
} from '@/jbi-shared/types/jaas-group-level-attributes.types';
import AttributeActionButtons from './AttributeActionButtons.vue';

@Component({
  components: {
    DraggableIcon,
    AttributeActionButtons
  }
})
export default class GroupLevelAttributeListField extends Vue {
  @Prop() attributeValue!: GroupLevelAttributeValue;
  @Prop() fieldClasses!: any;
  @Prop() duplicationError!: boolean;
  @Prop() deleteAttribute!: () => void;
  @Prop() updateAttributeValue!: (attribute: GroupLevelAttributeValue) => void;
  @Prop() toggleAttributeRequire!: (
    attribute: GroupLevelAttributeValue
  ) => void;
  @Prop() editAttribute!: (attribute: GroupLevelAttributeValue) => void;

  fieldErrorMsg: string = '';

  get listValue(): GroupLevelAttributeListType {
    return this.attributeValue.value as GroupLevelAttributeListType;
  }

  get listOption(): GroupLevelAttributeListType {
    return this.attributeValue.option as GroupLevelAttributeListType;
  }

  // Computed property so changes from the value from parent component are synced
  get isRequired() {
    return this.attributeValue.groupLevelAttributeSpec.isRequired;
  }

  mounted() {
    this.validateRequiredFieldValues();
  }

  assignValue(): void {
    this.attributeValue.value = this.listValue;
    this.attributeValue.value.options = (this.attributeValue
      .option as GroupLevelAttributeListType).options;
  }

  clearRadioButtonValue(element: any): void {
    const clickedValue: string = element.target.parentElement.children[0].value;

    if (clickedValue === this.listValue.selected) {
      this.listValue.selected = '';
    }
  }

  /**
   * Add a separate watcher for "hasDuplicationError" property
   * because this property is updated by parent.
   */
  @Watch('duplicationError')
  duplicationCallback(currentError: boolean, previousError: boolean): void {
    this.updateAttributeValue(this.attributeValue);
  }

  @Watch('listValue', { deep: true })
  listOptionChangeCallback(): void {
    this.assignValue();
    this.updateAttributeValue(this.attributeValue);
  }

  @Watch('attributeValue.value', { deep: true })
  @Watch('isRequired', { immediate: true })
  validateRequiredFieldValues(): void {
    /**
     * A required list must have:
     * 1. selections
     * 2. selected value(s)
     */
    if (this.attributeValue.groupLevelAttributeSpec.isRequired) {
      if (
        (this.attributeValue.value as GroupLevelAttributeListType).options
          .length === 0
      ) {
        this.fieldErrorMsg = 'Required list cannot be empty.';
        this.attributeValue.hasFieldError = true;
        this.updateAttributeValue(this.attributeValue);
        return;
      }

      if (
        (this.attributeValue.value as GroupLevelAttributeListType).selected
          .length === 0
      ) {
        this.fieldErrorMsg = 'Required list must have selected value(s).';
        this.attributeValue.hasFieldError = true;
        this.updateAttributeValue(this.attributeValue);
        return;
      }
    }

    this.fieldErrorMsg = '';
    this.attributeValue.hasFieldError = false;
    this.updateAttributeValue(this.attributeValue);
  }
}
