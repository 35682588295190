















































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ApiState } from '@/store/types/general.types';
import { RootState } from '../../../../store/store';
import {
  GroupLevelAttributeUploadableSignedUrlRequestPayload,
  UploadableFieldMessage,
  DocumentFileSizeLimit,
  ImageFileSizeLimit
} from '../../../../store/modules/admin/types/group-level-attribute.types';
import FileUpload from '@/components/FileUpload.vue';
import DraggableIcon from '../GroupLevelAttributes/DraggableIcon.vue';
import { GetSignedUrlForUploadResponsePayload } from '../../../../store/modules/static-file/types/static-file.types';
import { ToastProgrammatic as Toast } from 'buefy';
import draggable from 'vuedraggable';
import { saveAs } from 'file-saver';
import { BLoadingComponent } from 'buefy/types/components';
import { GroupLevelAttributeValue } from '@/jbi-shared/types/jaas-group-level-attributes.types';
import AttributeActionButtons from './AttributeActionButtons.vue';

@Component({
  components: {
    FileUpload,
    draggable,
    DraggableIcon,
    AttributeActionButtons
  }
})
export default class GroupLevelAttributeUploadable extends Vue {
  @Prop() attributeValue!: GroupLevelAttributeValue;
  @Prop() fieldClasses!: any;
  @Prop() duplicationError!: boolean;
  @Prop() deleteAttribute!: () => void;
  @Prop() updateAttributeValue!: (attribute: GroupLevelAttributeValue) => void;
  @Prop() toggleAttributeRequire!: (
    attribute: GroupLevelAttributeValue
  ) => void;
  @Prop() editAttribute!: (attribute: GroupLevelAttributeValue) => void;

  // prettier-ignore
  dropZoneName: string = `dropZone_${this.attributeValue.label}_${this.attributeValue.groupLevelAttributeType.type}`;
  fieldErrorMsg: string = '';

  selectedFiles: File[] = [];
  isUploading: boolean | undefined = false;
  spinner: BLoadingComponent | undefined = undefined;

  // Should always return empty array
  get attributeValueAsStringArray(): string[] {
    return (this.attributeValue.value as []) ?? [];
  }

  @Action('staticFile/getSignedUrlForGroupLevelAttributeUploadable')
  getUploadableSignedUrl!: (payload: {
    data: GroupLevelAttributeUploadableSignedUrlRequestPayload[];
  }) => void;

  @State(
    ({ staticFile }: RootState) =>
      staticFile.apiState.getGroupLevelAttributeSignedUrls
  )
  public getSignedUrlsState!: ApiState;

  @State(
    ({ staticFile }: RootState) => staticFile.groupLevelAttributeSignedUrls
  )
  public uploadableSignedUrls!:
    | GetSignedUrlForUploadResponsePayload[]
    | undefined;

  // Computed property so changes from the value from parent component are synced
  get isRequired() {
    return this.attributeValue.groupLevelAttributeSpec.isRequired;
  }

  get fileSizeLimit(): number {
    switch (this.attributeValue.groupLevelAttributeType.type) {
      case 'documents':
        return DocumentFileSizeLimit;
      case 'images':
        return ImageFileSizeLimit;
      default:
        return ImageFileSizeLimit;
    }
  }

  getErrorMessageAndType(): UploadableFieldMessage {
    const fieldDisplay = {
      type: '',
      message: ''
    };

    if (this.attributeValue.hasFieldError && !this.isUploading) {
      fieldDisplay.type = 'is-danger';
      fieldDisplay.message = 'Please upload at least one file.';
    } else if (this.isUploading) {
      fieldDisplay.type = 'is-info';
      fieldDisplay.message = 'Uploading files...';
    }

    return fieldDisplay;
  }

  mounted() {
    this.validateRequiredFieldValues();
  }

  removeUploadedContent(index: number) {
    (this.attributeValue.value as string[]).splice(index, 1);
  }

  processFile(selectedFiles: File[]) {
    if (selectedFiles.length === 0) {
      return;
    }

    const oversizedFile = selectedFiles.find((file: File) => {
      return file.size > this.fileSizeLimit;
    });

    if (oversizedFile) {
      Toast.open({
        message: `File '${oversizedFile.name}' has exceeded size limitation.`,
        type: 'is-danger',
        position: 'is-top'
      });
      this.clearUploadingState();
      return;
    }

    this.activateLoadingSpinner();
    this.isUploading = true;
    this.getSignedUrl(selectedFiles);
  }

  getSignedUrl(files: File[]) {
    // method to synchronously get signed url for selected files
    return this.getUploadableSignedUrl({
      data: files.map((file: File) => {
        return {
          fileName: file.name
            .replaceAll(' ', '_')
            .replaceAll('(', '_')
            .replaceAll(')', '_'),
          contentType: file.type
        };
      })
    });
  }

  async uploadFileToSignedUrl(
    selectedFiles: File[],
    signedUrls: GetSignedUrlForUploadResponsePayload[]
  ) {
    try {
      selectedFiles.forEach((file, fileIndex) => {
        const formData = new FormData();
        formData.append('file', file as Blob);

        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.open('PUT', signedUrls[fileIndex].signedUrl, true);

          xhr.onload = (e) => {
            // upload complete
            (this.attributeValue.value as string[]).push(
              signedUrls[fileIndex].storageUrl
            );
            return resolve(signedUrls);
          };

          xhr.onerror = (error) => {
            return reject(error);
          };

          xhr.send(file);
        });
      });
    } catch (error: any) {
      this.showUploadToastError();
    }
  }

  showUploadToastError(message?: string) {
    Toast.open({
      message: message || 'Upload failed. Please try again.',
      type: 'is-danger',
      position: 'is-top'
    });
  }

  activateLoadingSpinner() {
    // activate loader component in drop zone
    this.spinner = this.$buefy.loading.open({
      container: this.$refs[this.dropZoneName]
    });
  }

  clearUploadingState() {
    this.selectedFiles.length = 0;
    this.isUploading = false;
    this.spinner?.close();
  }

  downloadFile(fileUrl: string) {
    return saveAs(fileUrl, fileUrl.split('/').pop());
  }

  @Watch('getSignedUrlsState', { deep: true })
  onStateChange(state: ApiState) {
    if (state.error) {
      // reset on error
      this.showUploadToastError();
      this.clearUploadingState();
    }
  }

  @Watch('uploadableSignedUrls', { deep: true })
  uploadOnUrlRequestSuccess(
    signedUrls: GetSignedUrlForUploadResponsePayload[]
  ) {
    if (signedUrls) {
      this.uploadFileToSignedUrl(this.selectedFiles, signedUrls)
        .then()
        .catch((e) => this.showUploadToastError())
        .finally(() => this.clearUploadingState());
    }
  }

  /**
   * Add a separate watcher for "hasDuplicationError" property
   * because this property is updated by parent.
   */
  @Watch('duplicationError')
  duplicationCallback(currentError: boolean, previousError: boolean): void {
    this.updateAttributeValue(this.attributeValue);
  }

  @Watch('attributeValue.value', { deep: true, immediate: true })
  @Watch('isRequired', { immediate: true })
  validateRequiredFieldValues() {
    if (
      this.attributeValue.groupLevelAttributeSpec.isRequired &&
      (this.attributeValue.value as string[]).length === 0
    ) {
      this.fieldErrorMsg = 'Upload at least one file.';
      this.attributeValue.hasFieldError = true;
      this.updateAttributeValue(this.attributeValue);
      return;
    }

    this.fieldErrorMsg = '';
    this.attributeValue.hasFieldError = false;
    this.updateAttributeValue(this.attributeValue);
  }

  // Ensure value is always initialized as emtpy array
  @Watch('attributeValue', { immediate: true })
  onPropLoaded() {
    if (!this.attributeValue.value) {
      this.attributeValue.value = [];
    }
  }
}
