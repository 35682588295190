var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(!_vm.attributeValue.isDisabled)?_c('DraggableIcon'):_vm._e(),_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('b',{staticClass:"level-item"},[(_vm.attributeValue.groupLevelAttributeSpec.isRequired)?_c('span',{class:_vm.fieldClasses.asterisk},[_vm._v("* ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.attributeValue.label)+" ")])]),_c('AttributeActionButtons',{attrs:{"isRequired":_vm.attributeValue.groupLevelAttributeSpec.isRequired,"disabled":_vm.attributeValue.groupLevelAttributeSpec.isDefault},on:{"deleteAttribute":_vm.deleteAttribute,"editAttribute":function($event){return _vm.editAttribute(_vm.attributeValue)},"toggleAttributeRequire":function($event){return _vm.toggleAttributeRequire(_vm.attributeValue)}}})],1),_c('div',{class:_vm.$style.uploadedContents},[_c('div',{staticClass:"container"},[(
          _vm.attributeValueAsStringArray.length &&
          _vm.attributeValue.groupLevelAttributeType.type === 'documents'
        )?_c('div',[_c('draggable',{class:_vm.$style.documents,attrs:{"tag":"ul","list":_vm.attributeValue.value}},_vm._l((_vm.attributeValueAsStringArray),function(url,index){return _c('li',{key:index},[(_vm.attributeValue.value)?_c('b-button',{class:_vm.$style.deleteImageIcon,on:{"click":function($event){return _vm.removeUploadedContent(index)}}},[_c('b-icon',{attrs:{"size":"is-small","icon":"close"}})],1):_vm._e(),_c('a',{class:_vm.$style.links,on:{"click":function($event){return _vm.downloadFile(url)}}},[_vm._v(" "+_vm._s(url.split('/').pop())+" ")])],1)}),0)],1):_vm._e(),(
          _vm.attributeValueAsStringArray.length &&
          _vm.attributeValue.groupLevelAttributeType.type === 'images'
        )?_c('div',[_c('draggable',{class:_vm.$style.images,attrs:{"tag":"div","list":_vm.attributeValue.value}},_vm._l((_vm.attributeValue.value),function(url,index){return _c('div',{key:index,style:({
              backgroundImage: ("url(" + url + ")")
            })},[(_vm.attributeValue.value)?_c('b-button',{class:_vm.$style.deleteImageIcon,on:{"click":function($event){return _vm.removeUploadedContent(index)}}},[_c('b-icon',{attrs:{"size":"is-small","icon":"close"}})],1):_vm._e()],1)}),0)],1):_vm._e(),(_vm.attributeValueAsStringArray.length === 0)?_c('div',[_c('p',{staticClass:"has-text-centered is-italic mb-2",class:_vm.$style.noValue},[_vm._v(" No file uploaded. ")])]):_vm._e()]),_c('div',{staticClass:"container"},[_c('b-field',{staticClass:"has-text-centered",attrs:{"message":_vm.getErrorMessageAndType().message,"type":_vm.getErrorMessageAndType().type}},[_c('b-upload',{class:_vm.$style.uploadArea,attrs:{"drag-drop":"","accept":_vm.attributeValue.groupLevelAttributeType.type === 'images'
              ? '.jpg,.jpeg,.png'
              : '.txt,.csv,.doc,.pdf',"multiple":true,"disabled":!!_vm.attributeValue.isDisabled},on:{"input":function($event){return _vm.processFile($event)}},model:{value:(_vm.selectedFiles),callback:function ($$v) {_vm.selectedFiles=$$v},expression:"selectedFiles"}},[_c('div',{ref:_vm.dropZoneName,class:['has-text-centered', 'm-5', _vm.$style.dropZone]},[_c('img',{attrs:{"src":require("@/assets/images/uploader/Shape.png")}}),_vm._v(" "),_c('br'),_c('p',[_c('b',[_vm._v(" Drag and drop to upload "+_vm._s(_vm.attributeValue.groupLevelAttributeType.type)+" ")]),_vm._v(" or "),_c('a',[_vm._v("choose file")]),_c('br'),(
                  _vm.attributeValue.groupLevelAttributeType.type === 'images'
                )?_c('span',[_vm._v(" (.jpg, .jpeg, or .png file up to 2MB) ")]):_c('span',[_vm._v(" (.txt, .csv, or .pdf file up to 5MB) ")])])])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }