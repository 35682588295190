export function cleanString(str: string | undefined) {
  return str ? str.replace(/\s+/g, ' ').trim() : str;
}

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} str - The input string to capitalize.
 * @returns {string} The input string with the first letter capitalized.
 * If the input is empty or nullish, returns the original input unchanged.
 *
 * @example
 * // Returns "Hello"
 * capitalize("hello");
 *
 * @example
 * // Returns "Test string"
 * capitalize("test string");
 */
export const capitalize = (str: string) => {
  if (!str) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Transforms a string to capital/title case.
 * @example
 * toCapitalCase('list')       -> 'List'
 * toCapitalCase('text area')  -> 'Text Area'
 * @param str The input string to transform
 */
export function toCapitalCase(str: string): string {
  if (!str) {
    return str;
  }

  return str
    .split(' ')
    .map((word) => {
      if (word.length === 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}
