import { MyjbiGroup } from './myjbi-group.types';

/* Group Level Attribute Interfaces
 * These should match their counterparts in dashboard types.
 * file path: dashboard/src/store/modules/admin/types/group-level-attribute.types.ts */
export type AttributeValueType =
  | GroupLevelAttributeOption[]
  | GroupLevelAttributeContactsType[]
  | GroupLevelAttributeListType
  | string
  | string[]
  | null;

export type AttributeOptionType =
  | GroupLevelAttributeOption[]
  | GroupLevelAttributeContactsType[]
  | GroupLevelAttributeListType
  | null;

export interface GroupLevelAttributeOption {
  placeholder: string;
  value: AttributeOptionValueType;
  status: GroupLevelAttributeValueStatus | GroupLevelAttributeOptionStatus;
  attributeType: GroupLevelAttributeOptionType;
  isRequired?: boolean | false;
}

export interface GroupLevelAttributeListType {
  options: string[];
  selected: string[] | string;
  isSingleSelection: boolean;
}

export interface GroupAttribute {
  order: number;
  label: string;
  isRequired: boolean;
  groupLevelAttributeType: GroupLevelAttributeType;
  id?: number;
  createdDate?: Date;
  updatedDate?: Date;
  deletedDate?: Date | null;
  hasDuplicationError?: boolean;
}

export interface GroupLevelAttributeValueOld extends GroupAttribute {
  value: AttributeValueType;
  status: GroupLevelAttributeValueStatus;
  hasFieldError?: boolean;
  isTitle?: boolean;
  isDefault?: boolean;
}

export interface GroupLevelAttributeOptionType {
  id: number;
  type: string;
}

export interface GroupLevelAttributeType {
  id: number;
  type: string;
  option: AttributeOptionType;
}

export type AttributeOptionValueType = string | string[] | null;

export interface GroupLevelAttributeContactsType {
  id?: number;
  contactOptions: GroupLevelAttributeOption[];
  // property is for used for frontend validation purposes
  errorMessage?: ContactOptionsErrorMessages;
}

export interface ContactOptionsErrorMessages {
  nameErrorMessage: string;
  emailErrorMessage: string;
  phoneErrorMessage: string;
}

export enum GroupLevelAttributeValueStatus {
  ACTIVE = 'ACTIVE',
  DELETE = 'DELETE',
}

export enum GroupLevelAttributeOptionStatus {
  ACTIVE = 'ACTIVE',
  DELETE = 'DELETE',
}

export enum GroupLevelAttributeStatus {
  ACTIVE = 'ACTIVE',
  DELETE = 'DELETE',
}

export enum GroupLevelAttributeTemplateStatus {
  ACTIVE = 'ACTIVE',
  DELETE = 'DELETE',
}

export enum GroupLevelAttributeTemplateMapStatus {
  ACTIVE = 'ACTIVE',
  DELETE = 'DELETE',
}

export interface GroupLevelAttribute {
  id?: number;
  label: string;
  option: AttributeOptionType;
  groupLevelAttributeType: GroupLevelAttributeType;
  attributeStatus: GroupLevelAttributeStatus;
}

export interface GroupLevelAttributeWithSpec extends GroupLevelAttribute {
  groupLevelAttributeSpec: GroupLevelAttributeSpec;
  mapId?: number;
  mapStatus?: GroupLevelAttributeTemplateMapStatus | GroupLevelAttributeValueStatus;
}

export interface GroupLevelAttributeSpec {
  id?: number;
  order: number;
  isRequired: boolean;
  isDefault?: boolean;
}

export interface GroupLevelAttributeTemplate {
  id?: number;
  name: string;
  groupLevelAttributesWithSpecs: GroupLevelAttributeWithSpec[];
  status?: GroupLevelAttributeTemplateStatus;
}

export interface AttributeOptions {
  groupLevelAttributeId: number;
  order: number;
  isRequired: boolean;
  mapId?: number;
  groupLevelAttributeSpecId?: number;
  status?: GroupLevelAttributeTemplateMapStatus | GroupLevelAttributeValueStatus;
}

export interface GroupLevelAttributeValue extends GroupLevelAttributeWithSpec {
  value: AttributeValueType;
  // Property is for used for frontend validation purposes
  hasFieldError?: boolean;
  hasDuplicationError?: boolean;
  isTitle?: boolean;
  isDisabled?: boolean;
  group?: MyjbiGroup;
}

export interface AttributeOptionsWithValue extends AttributeOptions {
  value: AttributeValueType;
}

export enum DataActionType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}
