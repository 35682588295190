var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card has-modal-small"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title",class:_vm.$style.titleHeader},[_vm._v(" "+_vm._s(_vm.glaTemplate.name)+" ")]),_c('button',{staticClass:"close-icon",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{staticClass:"modal-card-body"},[_c('p',{class:_vm.$style.attributeCount},[_vm._v(" "+_vm._s(_vm.glaTemplate.groupLevelAttributesWithSpecs.length)+" attribute(s) ")]),_c('BaseTable',{attrs:{"isBorderless":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',[_vm._v("Content Type")]),_c('th',[_vm._v("Label")])])]},proxy:true},{key:"body",fn:function(){return _vm._l((_vm.glaTemplate.groupLevelAttributesWithSpecs),function(attribute){return _c('tr',{key:attribute.id},[_c('td',[_c('span',[_c('i',{staticClass:"mdi mdi-asterisk",class:[
                  _vm.$style.asterisk,
                  attribute.groupLevelAttributeSpec.isRequired
                    ? _vm.$style.isRed
                    : ''
                ]}),_vm._v(" "+_vm._s(_vm.convertToCapitalCase(attribute.groupLevelAttributeType.type))+" ")]),_c('p'),(attribute.groupLevelAttributeType.type === 'list')?_c('ul',{class:_vm.$style.listSelections},[(
                  _vm.attributeOptionsForListGLA(attribute).options.length === 0
                )?_c('li',[_c('i',[_vm._v("List is empty.")])]):_vm._e(),_vm._l((_vm.attributeOptionsForListGLA(
                  attribute
                ).options),function(listItem,index){return (
                  _vm.attributeOptionsForListGLA(attribute).options.length > 0
                )?_c('li',{key:index,class:_vm.attributeOptionsForListGLA(attribute).isSingleSelection
                    ? _vm.$style.singleSelection
                    : _vm.$style.multiSelection},[_c('i',[_vm._v(" "+_vm._s(listItem)+" ")])]):_vm._e()})],2):_vm._e(),(attribute.groupLevelAttributeType.type === 'address')?_c('ul',{class:_vm.$style.listSelections},[(_vm.attributeOptionsForAddressGLA(attribute).length === 0)?_c('li',[_c('i',[_vm._v("Address line options are empty.")])]):_vm._e(),_vm._l((_vm.attributeOptionsForAddressGLA(
                  attribute
                )),function(addressLine,index){return _c('li',{key:index,class:_vm.$style.singleSelection},[_c('i',[_vm._v(" "+_vm._s(_vm.convertToCapitalCase(addressLine.placeholder))+" ")])])})],2):_vm._e()]),_c('td',[_vm._v(_vm._s(attribute.label))])])})},proxy:true}])})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{staticClass:"button is-primary",on:{"click":_vm.closeModal}},[_vm._v("Done")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }