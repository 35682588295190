
































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { RootState } from '../../../store/store';
import { ApiState } from '../../../store/types/general.types';
import { ToastProgrammatic as Toast } from 'buefy';
import BaseLoading from '@/components/base/BaseLoading.vue';
import {
  clone,
  isDefaultGroupAttribute
} from '@/jbi-shared/util/group-level-attributes.util';
import {
  AttributeOptionType,
  GroupLevelAttribute,
  GroupLevelAttributeListType,
  GroupLevelAttributeOption,
  GroupLevelAttributeType
} from '@/jbi-shared/types/jaas-group-level-attributes.types';
import {
  CreateGroupLevelAttributeDTO,
  UpdateGroupLevelAttributeDTO
} from '@/jbi-shared/dto/jaas-group-level-attributes.dtos';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { AxiosError } from 'axios';

@Component({
  components: {
    BaseLoading
  }
})
export default class EditAttributeModal extends Vue {
  @Prop(Number) attributeId!: number;

  // Payload related variables
  label: string = '';
  updatedLabel: string = '';
  type: string = '';
  contentType: GroupLevelAttributeType | null = null;
  option: AttributeOptionType | null = null;
  attributeOptionValues: string[] = [];
  isSingleSelection: boolean = false;
  optionPayload: AttributeOptionType = null;
  overrideDuplicateCheck: boolean = false;

  // Validation related variables
  disableSave: boolean = true;
  isDuplicateAttributeFound: boolean = false;
  duplicationErrorMsg: string = '';
  attributeOptionErrorIndexes: number[] = [];
  clonedCurrentGroupLevelAttribute: GroupLevelAttribute | null = null;

  @Action('admin/getGroupLevelAttributeById')
  public getGroupLevelAttributeById!: (attributeId: number) => void;

  @Action('admin/verifyGroupLevelAttribute')
  public verifyGroupLevelAttribute!: (
    payload: CreateGroupLevelAttributeDTO
  ) => void;

  @Action('admin/updateGroupLevelAttribute')
  public updateGroupLevelAttribute!: (
    payload: UpdateGroupLevelAttributeDTO
  ) => void;

  @State(({ admin }: RootState) => admin.apiState.updateGroupLevelAttribute)
  public updateGroupLevelAttributeApiState!: ApiState;

  @State(
    ({ admin }: RootState) => admin.apiState.verifyGroupLevelAttribute.success
  )
  public verifyGroupLevelAttributeSuccess!: boolean;

  @State(({ admin }: RootState) => admin.apiState.getGroupLevelAttributeById)
  public getGroupLevelAttributeByIdApiState!: ApiState;

  @State(({ admin }: RootState) => admin.verifyGroupLevelAttribute)
  public isDuplicateGroupLevelAttribute!: boolean;

  @State(({ admin }: RootState) => admin.groupLevelAttribute)
  public groupLevelAttribute!: GroupLevelAttribute;

  get attributeOptionsAsOptionType() {
    if (this.type === 'address') {
      if (!this.option) {
        this.option = clone(
          this.groupLevelAttribute.groupLevelAttributeType
            .option as GroupLevelAttributeOption[]
        );
        return this.option;
      }
      return this.option as GroupLevelAttributeOption[];
    }
  }

  get attributeOptionsAsListType() {
    if (this.type === 'list') {
      return this.option as GroupLevelAttributeListType;
    }
  }

  get buildingName() {
    if (this.attributeOptionsAsOptionType) {
      return this.attributeOptionsAsOptionType[0];
    }
  }

  get addressLineTwo() {
    if (this.attributeOptionsAsOptionType) {
      return this.attributeOptionsAsOptionType[2];
    }
  }

  get isDefault(): boolean {
    return isDefaultGroupAttribute(this.groupLevelAttribute);
  }

  get isActualDuplicateAndNotOriginalState(): boolean {
    return this.isDuplicateAttributeFound && !this.isOriginalState();
  }

  created() {
    this.getGroupLevelAttributeById(this.attributeId);
  }

  /**
   * Validates list-type Group Level Attributes (GLAs) for duplicate options.
   *
   * Note: Empty or whitespace-only values are ignored during duplicate checking.
   * First occurrence of a value is considered valid, subsequent occurrences are marked as duplicates.
   */
  checkAttributeOptionDuplicateValues() {
    this.attributeOptionErrorIndexes = [];
    this.attributeOptionValues.forEach((item, index) => {
      if (item.trim() && this.attributeOptionValues.indexOf(item) !== index) {
        this.attributeOptionErrorIndexes.push(index);
      }
    });
  }

  /**
   * Determines if the save/submit button should be disabled based on form validation rules.
   *
   * Button is disabled when:
   * 1. Invalid payload conditions:
   *    - Empty attribute label
   *    - Duplicate attribute detected
   *    - Duplicate group level attribute status unknown
   *    - General save disabled flag
   * 2. Form is in its original unchanged state
   *
   * Additional validation for list-type attributes:
   *    - Must have at least 2 valid options
   *    - No validation errors in option entries
   */
  isButtonDisabled(): boolean {
    const isInvalidPayload: boolean =
      this.updatedLabel === '' ||
      this.isDuplicateAttributeFound ||
      this.isDuplicateGroupLevelAttribute === undefined ||
      this.disableSave;

    if (this.type === 'list') {
      return (
        isInvalidPayload ||
        this.isOriginalState() ||
        this.attributeOptionErrorIndexes.length > 0 ||
        // Must have at least 2 options
        !(this.getAttributeOptionValues().length > 1)
      );
    }

    return isInvalidPayload || this.isOriginalState();
  }

  isOriginalState(): boolean {
    const isNameChanged: boolean =
      this.updatedLabel === this.groupLevelAttribute.label;

    if (this.option && this.type === 'list') {
      // Ensure both arrays contain the same elements without extra elements in either.
      const isOptionValuesChanged: boolean =
        this.attributeOptionValues.every((item) =>
          (this.clonedCurrentGroupLevelAttribute!
            .option as GroupLevelAttributeListType).options.includes(item)
        ) &&
        (this.clonedCurrentGroupLevelAttribute!
          .option as GroupLevelAttributeListType).options.every((item) =>
          this.attributeOptionValues.includes(item)
        );

      const isSelectionTypeChanged: boolean =
        (this.clonedCurrentGroupLevelAttribute!
          .option as GroupLevelAttributeListType).isSingleSelection ===
        (this.option as GroupLevelAttributeListType).isSingleSelection;

      return isNameChanged && isOptionValuesChanged && isSelectionTypeChanged;
    }

    if (this.option && this.type === 'address') {
      const isBuildingNameChanged: boolean =
        this.attributeOptionsAsOptionType![0].status ===
        (this.clonedCurrentGroupLevelAttribute
          ?.option as GroupLevelAttributeOption[])[0].status;
      const isAddressLineTwoChanged: boolean =
        this.attributeOptionsAsOptionType![2].status ===
        (this.clonedCurrentGroupLevelAttribute
          ?.option as GroupLevelAttributeOption[])[2].status;
      return isNameChanged && isBuildingNameChanged && isAddressLineTwoChanged;
    }
    return isNameChanged;
  }

  updateAttributeConfirmation(): void {
    this.$buefy.dialog.confirm({
      message: `<p class="buefy-dialog-title">Save Changes?</p><p class="buefy-dialog-content">Changes made will be applied <b>TO ALL GROUPS</b> associated with this attribute. Do you still wish to continue?</p>`,
      confirmText: 'Save',
      onConfirm: this.handleUpdate,
      canCancel: ['button'],
      cancelText: 'Cancel',
      onCancel: undefined
    });
  }

  handleUpdate() {
    this.constructAttributeOptionPayload();
    this.updateGroupLevelAttribute({
      groupLevelAttributeId: this.attributeId,
      labelName: this.updatedLabel,
      option: this.option,
      overrideDuplicateCheck: this.overrideDuplicateCheck
    });
  }

  /**
   * Only capture strings with values.
   * As there might be empty string when the field is clear.
   * Empty option field should not block submission.
   */
  getAttributeOptionValues() {
    return this.attributeOptionValues.filter((value) => value.length);
  }

  onProceed(): void {
    this.disableSave = false;
    this.isDuplicateAttributeFound = false;
    this.overrideDuplicateCheck = true;
  }

  closeModal(): void {
    this.$emit('close');
  }

  removeOptionListItem(event: any, identifierClass: string, itemIndex: number) {
    if (event.target.parentElement.className.includes(identifierClass)) {
      this.attributeOptionValues.splice(itemIndex, 1);
      this.checkAttributeOptionDuplicateValues();
    }
  }

  addOptionListItem() {
    this.attributeOptionValues.push('');
  }

  constructAttributeOptionPayload() {
    // Account for different payload types
    switch (this.type) {
      case 'list':
        this.optionPayload = this.option as GroupLevelAttributeListType;
        break;
      case 'address':
      case 'coordinates':
        this.optionPayload = this.option as GroupLevelAttributeOption[];
        break;
      default:
        // GLA types other than list, address and coordinates should have null options
        this.optionPayload = null;
        break;
    }
  }

  @Debounce(700)
  validateDuplicate() {
    if (this.updatedLabel.length >= 1) {
      if (this.isDefault) {
        this.duplicationErrorMsg = `'${this.updatedLabel}' is a default attribute. Please use a different type or label name.`;
        this.isDuplicateAttributeFound = true;
        return;
      }

      this.constructAttributeOptionPayload();
      this.verifyGroupLevelAttribute({
        labelName: this.updatedLabel,
        groupLevelAttributeTypeId: this.contentType!.id,
        option: this.optionPayload
      });
      this.duplicationErrorMsg = '';
      this.isDuplicateAttributeFound = false;
    } else {
      this.isDuplicateAttributeFound = false;
    }
  }

  @Watch('updatedLabel')
  @Watch('attributeOptionsAsListType.isSingleSelection')
  @Watch('attributeOptionValues', { deep: true })
  @Watch('buildingName', { deep: true })
  @Watch('addressLineTwo', { deep: true })
  public onFieldsUpdated() {
    this.isDuplicateAttributeFound = false; // clear debounced state
    this.disableSave = true;
    // Only perform validation callback if form is changed
    if (!this.isOriginalState()) {
      this.validateDuplicate();
    }
  }

  @isTruthy
  @Watch('verifyGroupLevelAttributeSuccess')
  public watchVerifyGroupLevelAttributeSuccess(): void {
    if (this.isDuplicateGroupLevelAttribute) {
      this.isDuplicateAttributeFound = true;
    } else {
      this.onProceed();
    }
  }

  @Watch('option.isSingleSelection')
  onSingleSelectionChanged() {
    if (this.type === 'list') {
      /**
       * Modify the `selected` property.
       * Single selection should be empty string,
       * Multiple selection should be empty array of strings.
       */
      this.option = {
        ...(this.option as GroupLevelAttributeListType),
        selected: (this.option as GroupLevelAttributeListType).isSingleSelection
          ? ('' as string)
          : ([] as string[])
      };
    }
  }

  @Watch('updateGroupLevelAttributeApiState', { deep: true })
  public onUpdateGroupLevelAttributeApiStateChange(state: ApiState): void {
    if (state.success) {
      Toast.open({
        message: 'Group level attribute updated successfully.',
        type: 'is-success',
        position: 'is-top'
      });

      this.$emit('attributeUpdateSuccess', {
        ...this.groupLevelAttribute,
        label: this.updatedLabel,
        option: this.option
      } as GroupLevelAttribute);

      this.closeModal();
    }

    if (state.error) {
      Toast.open({
        message: (state.error as AxiosError).response?.data.message,
        type: 'is-danger',
        position: 'is-top'
      });
    }
  }

  @Watch('getGroupLevelAttributeByIdApiState', { deep: true })
  public onGetGroupLevelAttributeByIdApiStateChange(state: ApiState): void {
    if (state.error) {
      Toast.open({
        message: 'Error getting attribute details. Please try again.',
        type: 'is-danger',
        position: 'is-top',
        duration: 10000
      });
    }
  }

  @Watch('groupLevelAttribute', { deep: true })
  public storeAttributeData(attribute: GroupLevelAttribute): void {
    // Clone the current attribute to prevent direct mutation of the state
    this.clonedCurrentGroupLevelAttribute = clone(attribute);

    if (!attribute) {
      return;
    }

    this.label = attribute.label;
    this.updatedLabel = this.label;
    this.contentType = attribute.groupLevelAttributeType;
    this.type = attribute.groupLevelAttributeType.type;

    switch (this.type) {
      case 'list':
        // In case where attribute.opton is null, populate this.option with an empty GroupLevelAttributeListType object
        if (attribute.option === null) {
          this.option = {
            options: Array(2),
            selected: '',
            isSingleSelection: true
          };

          // Clone the default options object as fallback measure for empty options from DB
          this.clonedCurrentGroupLevelAttribute = {
            ...this.clonedCurrentGroupLevelAttribute,
            option: clone(this.option)
          };
        } else {
          this.option = clone(attribute.option as GroupLevelAttributeListType);
        }

        this.attributeOptionValues = this.option.options;
        this.isSingleSelection = this.option.isSingleSelection;
        break;
      case 'address':
        // In case where attribute.opton is null, populate this.option with an empty GroupLevelAttributeListType object
        if (attribute.option === null) {
          this.option = clone(
            attribute.groupLevelAttributeType
              .option as GroupLevelAttributeOption[]
          );

          // Clone the default options object as fallback measure for empty options from DB
          this.clonedCurrentGroupLevelAttribute = {
            ...this.clonedCurrentGroupLevelAttribute,
            option: clone(this.option)
          };
        } else {
          this.option = clone(attribute.option as GroupLevelAttributeOption[]);
        }
      case 'coordinates':
        this.option = clone(attribute.option as GroupLevelAttributeOption[]);
        break;
      default:
        // GLA types other than list, address and coordinates should have null options
        this.option = null;
        break;
    }
  }
}
