



















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DraggableIcon from './DraggableIcon.vue';
import {
  GroupLevelAttributeOption,
  GroupLevelAttributeValue
} from '@/jbi-shared/types/jaas-group-level-attributes.types';
import AttributeActionButtons from './AttributeActionButtons.vue';

@Component({
  components: {
    DraggableIcon,
    AttributeActionButtons
  }
})
export default class GroupLevelAttributeCoordinatesField extends Vue {
  @Prop() attributeValue!: GroupLevelAttributeValue;
  @Prop() fieldClasses!: any;
  @Prop() duplicationError!: boolean;
  @Prop() deleteAttribute!: () => void;
  @Prop() updateAttributeValue!: (attribute: GroupLevelAttributeValue) => void;
  @Prop() toggleAttributeRequire!: (
    attribute: GroupLevelAttributeValue
  ) => void;
  @Prop() editAttribute!: (attribute: GroupLevelAttributeValue) => void;

  latitudeErrorMsg: string = '';
  longitudeErrorMsg: string = '';

  // shorthand referenced for rendering only
  latitude: GroupLevelAttributeOption = (this.attributeValue
    .value as GroupLevelAttributeOption[])[0];
  longitude: GroupLevelAttributeOption = (this.attributeValue
    .value as GroupLevelAttributeOption[])[1];

  // Computed property so changes from the value from parent component are synced
  get isRequired() {
    return this.attributeValue.groupLevelAttributeSpec.isRequired;
  }

  mounted() {
    this.validateRequiredFieldValues();
  }

  /* Valid latitude range: from 90 to -90
   * Valid longitude range: from 180 to -180
   *
   * Non-mandatory field can be left empty,
   * but any input values must be valid coordinate values.
   * Validate each field separately. */
  areCoordinatesValid(): boolean {
    const latitude: number = parseFloat(this.latitude.value as string);
    const longitude: number = parseFloat(this.longitude.value as string);

    if (latitude < -90 || latitude > 90) {
      this.latitudeErrorMsg =
        'Latitude value out of range. (Value should range between -90 and 90.)';
    } else {
      this.latitudeErrorMsg = '';
    }

    if (longitude < -180 || longitude > 180) {
      this.longitudeErrorMsg =
        'Longitude value out of range. (Value should range between -180 and 180.)';
    } else {
      this.longitudeErrorMsg = '';
    }

    if (this.latitudeErrorMsg.length || this.longitudeErrorMsg.length) {
      return false;
    }

    return true;
  }

  /**
   * Add a separate watcher for "hasDuplicationError" property
   * because this property is updated by parent.
   */
  @Watch('duplicationError')
  duplicationCallback(currentError: boolean, previousError: boolean): void {
    this.updateAttributeValue(this.attributeValue);
  }

  @Watch('attributeValue.value', { deep: true })
  @Watch('isRequired', { immediate: true })
  validateRequiredFieldValues() {
    const coordinatesValidated = this.areCoordinatesValid();
    if (!coordinatesValidated) {
      this.attributeValue.hasFieldError = true;
      this.updateAttributeValue(this.attributeValue);
      return;
    }

    if (this.attributeValue.groupLevelAttributeSpec.isRequired) {
      for (const coordinate of [
        this.latitude,
        this.longitude
      ] as GroupLevelAttributeOption[]) {
        switch (coordinate.placeholder) {
          case 'Latitude':
            this.latitudeErrorMsg = !coordinate.value
              ? 'Latitude cannot be empty.'
              : '';

          case 'Longitude':
            this.longitudeErrorMsg = !coordinate.value
              ? 'Longitude cannot be empty.'
              : '';
        }
      }

      this.attributeValue.hasFieldError = ([
        this.latitude,
        this.longitude
      ] as GroupLevelAttributeOption[]).some(
        (coordinate: GroupLevelAttributeOption) => {
          return coordinate.value === '' || coordinate.value === null;
        }
      );

      if (this.attributeValue.hasFieldError) {
        this.updateAttributeValue(this.attributeValue);
        return;
      }
    }

    this.latitudeErrorMsg = '';
    this.longitudeErrorMsg = '';
    this.attributeValue.hasFieldError = false;
    this.updateAttributeValue(this.attributeValue);
  }
}
