
























































































































































import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import { GroupLevelAttributeType } from '@/store/modules/admin/types/group-level-attribute.types';
import draggable from 'vuedraggable';
import { ApiState } from '@/store/types/general.types';
import GroupLevelAttributeTextField from './GroupLevelAttributes/GroupLevelAttributeTextField.vue';
import GroupLevelAttributeTextArea from './GroupLevelAttributes/GroupLevelAttributeTextArea.vue';
import GroupLevelAttributeEmailField from './GroupLevelAttributes/GroupLevelAttributeEmailField.vue';
import GroupLevelAttributeNumberField from './GroupLevelAttributes/GroupLevelAttributeNumberField.vue';
import GroupLevelAttributeListField from './GroupLevelAttributes/GroupLevelAttributeListField.vue';
import GroupLevelAttributeBooleanField from './GroupLevelAttributes/GroupLevelAttributeBooleanField.vue';
import GroupLevelAttributeAddressField from './GroupLevelAttributes/GroupLevelAttributeAddressField.vue';
import GroupLevelAttributePhoneField from './GroupLevelAttributes/GroupLevelAttributePhoneField.vue';
import GroupLevelAttributeCoordinatesField from './GroupLevelAttributes/GroupLevelAttributeCoordinatesField.vue';
import GroupLevelAttributeUploadable from './GroupLevelAttributes/GroupLevelAttributeUploadable.vue';
import GroupLevelAttributeLinkField from './GroupLevelAttributes/GroupLevelAttributeLinkField.vue';
import GroupLevelAttributeDateField from './GroupLevelAttributes/GroupLevelAttributeDateField.vue';
import FieldWrapper from './GroupLevelAttributes/FieldWrapper.vue';
import TitleAttributeField from './GroupLevelAttributes/DefaultAttributes/TitleAttributeField.vue';
import LogoAttributeField from './GroupLevelAttributes/DefaultAttributes/LogoAttributeField.vue';
import {
  clone,
  isDefaultGroupAttribute,
  isLogoAttribute,
  isTitleAttribute
} from '@/jbi-shared/util/group-level-attributes.util';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import delay from 'delay';
import { ToastProgrammatic as Toast } from 'buefy';
import { isUserAllowed } from '@/utils/rbac.util';
import {
  PermissionsMatrixActionsEnum,
  ResourceExceptions
} from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';
import {
  GroupLevelAttribute,
  GroupLevelAttributeListType,
  GroupLevelAttributeOption,
  GroupLevelAttributeSpec,
  GroupLevelAttributeTemplate,
  GroupLevelAttributeValue,
  GroupLevelAttributeValueStatus,
  GroupLevelAttributeWithSpec
} from '@/jbi-shared/types/jaas-group-level-attributes.types';
import { AxiosError } from 'axios';
import CreateNewGroupLevelAttributeModal from './GroupLevelAttributes/CreateNewGroupLevelAttributeModal.vue';
import EditAttributeModal from '@/views/AdminGroupManagement/components/EditAttributeModal.vue';
import UseExistingGroupLevelAttributeTemplateModal from '@/views/AdminGroupManagement/components/GroupTemplates/UseExistingGroupLevelAttributeTemplateModal.vue';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    draggable,
    UseExistingGroupLevelAttributeTemplateModal,
    FieldWrapper,
    TitleAttributeField,
    LogoAttributeField
  }
})
export default class AdminGroupLevelAttribute extends Vue {
  @PropSync('groupName') syncedGroupName!: string;
  @Prop() groupId!: number;
  @Prop() isSettings!: boolean;
  @Prop() isGroupLevelAttributeTabOpened!: boolean;
  @Prop() groupTypeName!: string;
  @Prop() groupExceptions!: ResourceExceptions;

  availableAttributeTypes: GroupLevelAttributeType[] = [];
  selectedGroupAttributeValues: GroupLevelAttributeValue[] = [];
  activeGroupLevelAttributes: GroupLevelAttributeValue[] = [];
  defaultGroupLevelAttributes: GroupLevelAttributeValue[] = [];
  // Values in this array will be processed for soft deletion
  deleteGroupLevelAttributes: GroupLevelAttributeValue[] = [];

  textType: GroupLevelAttributeType | undefined = undefined;
  imageType: GroupLevelAttributeType | undefined = undefined;

  duplicationError: boolean = false;
  saveAsTemplate: boolean = false;
  templateName: string = '';
  templateNameError: boolean = this.templateName === '' && this.saveAsTemplate;
  templateNameErrorMsg: string = '';
  templateNameMsgType: string = '';

  /**
   * Actions and States: Group Level Attribute Types
   */
  @Action('admin/getGroupLevelAttributeTypes')
  getGroupLevelAttributeTypes!: () => void;

  @State(({ admin }: RootState) => admin.apiState.getGroupLevelAttributeTypes)
  getGroupLevelAttributeTypesState!: ApiState;

  @State(({ admin }: RootState) => admin.groupLevelAttributeTypes)
  allAttributeTypes!: GroupLevelAttributeType[] | undefined;

  /**
   * Actions and States: Default Group Level Attributes
   */
  @Action('admin/getDefaultGroupLevelAttributes')
  getDefaultGroupLevelAttributes!: () => void;

  @State(
    ({ admin }: RootState) => admin.apiState.getDefaultGroupLevelAttributes
  )
  getDefaultGroupLevelAttributesApiState!: ApiState;

  @State(({ admin }: RootState) => admin.defaultGroupLevelAttributes)
  defaultGLAs!: GroupLevelAttribute[];

  /**
   * Actions and States: Group Level Attribute Values
   */
  @Action('admin/getGroupLevelAttributeValuesByGroupId')
  getGroupLevelAttributeValuesByGroupId!: (groupId: number) => void;

  @State(
    ({ admin }: RootState) =>
      admin.apiState.getGroupLevelAttributeValuesByGroupId
  )
  getGroupLevelAttributeValuesByGroupIdState!: ApiState;

  @State(({ admin }: RootState) => admin.groupLevelAttributeValuesByGroupId)
  currentGroupAttributeValues!: GroupLevelAttributeValue[];

  /**
   * Actions and States: Group template
   */
  @Action('admin/verifyGroupLevelAttributeTemplateName')
  verifyGroupLevelAttributeTemplateName!: (templateName: string) => any;

  @State(({ admin }: RootState) => admin.groupLevelAttributeTemplate)
  groupLevelAttributeTemplate!: GroupLevelAttributeTemplate;

  @State(
    ({ admin }: RootState) =>
      admin.apiState.verifyGroupLevelAttributeTemplateName
  )
  verifyGroupLevelAttributeTemplateNameApiState!: ApiState;

  @State(({ admin }: RootState) => admin.verifyGroupLevelAttributeTemplateName)
  isDuplicateName!: boolean;

  get PermissionsMatrixActionsEnum() {
    return PermissionsMatrixActionsEnum;
  }

  get canCreateNewTemplate(): boolean {
    return this.activeGroupLevelAttributes.length > 0;
  }

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string,
    skipImplicitCheck?: boolean
  ): boolean {
    const instance = EntityTypes.GROUP + '_' + this.groupId;
    return this.isSettings
      ? isUserAllowed(
          action,
          module,
          EntityTypes.GROUP,
          this.groupTypeName,
          this.groupId,
          this.groupExceptions,
          skipImplicitCheck
        )
      : true;
  }

  created() {
    // Attribute types and default GLAs (title and logo) are fetched on component mount
    this.getGroupLevelAttributeTypes();
    this.getDefaultGroupLevelAttributes();
  }

  getTitleAttributeFieldComponent() {
    return TitleAttributeField;
  }

  getLogoAttributeFieldComponent() {
    return LogoAttributeField;
  }

  /*
   * Return field component for dynamic rendering of active attributes.
   */
  getFieldComponent(attrVal: GroupLevelAttributeValue) {
    switch (attrVal.groupLevelAttributeType.type) {
      case 'link':
        return GroupLevelAttributeLinkField;
      case 'text area':
        return GroupLevelAttributeTextArea;
      case 'email':
        return GroupLevelAttributeEmailField;
      case 'address':
        return GroupLevelAttributeAddressField;
      case 'integer':
        return GroupLevelAttributeNumberField;
      case 'boolean':
        return GroupLevelAttributeBooleanField;
      case 'list':
        return GroupLevelAttributeListField;
      case 'images':
      case 'documents':
        return GroupLevelAttributeUploadable;
      case 'coordinates':
        return GroupLevelAttributeCoordinatesField;
      case 'phone':
        return GroupLevelAttributePhoneField;
      case 'date':
        return GroupLevelAttributeDateField;
      default:
        return GroupLevelAttributeTextField;
    }
  }

  handleTemplateNameChange(newTemplateName: string): void {
    if (newTemplateName.trim() === '') {
      this.templateNameError = true;
      this.templateNameErrorMsg = 'Template name cannot be empty.';
      this.templateNameMsgType = 'is-danger';
      return;
    }

    this.clearNameFieldAttr();
    this.handleVerifyTemplateName();
  }

  clearNameFieldAttr(): void {
    this.templateNameError = false;
    this.templateNameErrorMsg = '';
    this.templateNameMsgType = '';
  }

  @Debounce(800)
  handleVerifyTemplateName() {
    this.verifyGroupLevelAttributeTemplateName(this.templateName);
  }

  /*
   * Attribute to update could be in active or default array.
   * Locate attribute before emitting to parent.
   */
  handleUpdateAttributeValue(updatedAttribute: GroupLevelAttributeValue) {
    const targetArray = updatedAttribute.groupLevelAttributeSpec.isDefault
      ? this.defaultGroupLevelAttributes
      : this.activeGroupLevelAttributes;

    const attributeIndex: number = targetArray.findIndex(
      (attribute) =>
        attribute.groupLevelAttributeSpec.order ===
        updatedAttribute.groupLevelAttributeSpec.order
    );

    if (attributeIndex !== -1) {
      targetArray[attributeIndex] = updatedAttribute;
    }

    this.updateParentValue();
  }

  openEditAttributeModal(
    attributeToEdit: GroupLevelAttributeValue,
    index: number
  ): void {
    this.$buefy.modal.open({
      parent: this,
      component: EditAttributeModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: false,
      canCancel: true,
      props: {
        attributeId: Number(attributeToEdit.id)
      },
      events: {
        attributeUpdateSuccess: (
          updatedAttribute: GroupLevelAttribute
        ): void => {
          /**
           * Special update procedure for address-type Group Level Attributes. Updates status of
           * building name (index 0) and address line two (index 2) components based on edited values.
           * If a component is marked for deletion (status='DELETE'), its value is reset to empty string
           * to ensure proper rendering in the UI.
           */
          if (updatedAttribute.groupLevelAttributeType.type === 'address') {
            // Map between known positions for address components ([0] is building name and [2] is address line two)
            const addressLineMap = [0, 2];

            addressLineMap.forEach((position) => {
              const updatedOption = (updatedAttribute.option as GroupLevelAttributeOption[])[
                position
              ];
              const currentValue = (attributeToEdit.value as GroupLevelAttributeOption[])[
                position
              ];

              currentValue.status = updatedOption.status;

              if (updatedOption.status === 'DELETE') {
                currentValue.value = '';
              }
            });
          }

          // Custom checking logic for lists
          if (updatedAttribute.groupLevelAttributeType.type === 'list') {
            const updatedOption = updatedAttribute.option as GroupLevelAttributeListType;
            const currentValue = attributeToEdit.value as GroupLevelAttributeListType;

            // Only perform `selected` transformation if there was a change in selection type
            if (
              currentValue.isSingleSelection !== updatedOption.isSingleSelection
            ) {
              currentValue.isSingleSelection = updatedOption.isSingleSelection;

              if (!updatedOption.isSingleSelection) {
                // Single select -> Multi select, reassign selected values into an array containing itself
                currentValue.selected = [currentValue.selected as string];
              } else {
                // Multi select -> Single select, default to first item in multi select array
                currentValue.selected = (currentValue.selected as string[])[0];
              }
            }
          }
          /**
           * Immutable approach for updating local arrary (create new template) to ensure changes
           * to attributes are reflected. This approach ensures Vue detects the change.
           *
           * For small arrays (dozens of items), the performance impact would be negligible.
           */
          const updatedItem = {
            ...this.activeGroupLevelAttributes[index],
            label: updatedAttribute.label,
            option: updatedAttribute.option,
            value: attributeToEdit.value
          };

          // Use splice to replace the item at index
          this.activeGroupLevelAttributes.splice(index, 1, updatedItem);
        }
      }
    });
  }

  clearAddressLineValue(
    attributeToEdit: GroupLevelAttributeValue,
    index: number
  ) {
    (attributeToEdit.value as GroupLevelAttributeOption[])[index].value = '';
  }

  /*
   * Update attribute value for deletion
   * and move attribute to delete array.
   */
  handleDeleteAttribute(attributeIndex: number) {
    const attributeToDelete: GroupLevelAttributeValue = this
      .activeGroupLevelAttributes[attributeIndex];
    this.assignDeleteValue(attributeToDelete);
    this.deleteGroupLevelAttributes.push(attributeToDelete);
    this.activeGroupLevelAttributes.splice(attributeIndex, 1);
    this.updateOrderingAndParentValue();
  }

  openAddGroupLevelAttributeModal() {
    this.$buefy.modal.open({
      parent: this,
      component: CreateNewGroupLevelAttributeModal,
      hasModalCard: true, // added to prevent breaking on mobile
      trapFocus: true,
      fullScreen: false,
      canCancel: true,
      props: {
        allAttributeTypes: this.availableAttributeTypes // clone types to avoid directly mutating state
      },
      events: {
        addNewAttribute: (attributeValue: GroupLevelAttributeWithSpec) => {
          const modifiedPayload: GroupLevelAttributeValue = {
            ...attributeValue,
            value: attributeValue.option,
            hasFieldError: false,
            hasDuplicationError: false,
            isTitle: false,
            isDisabled: false
          };
          this.activeGroupLevelAttributes.push(modifiedPayload);
          this.updateOrderingAndParentValue();

          Toast.open({
            queue: true,
            type: 'is-dark',
            position: 'is-top',
            message: `New attribute added`
          });
        }
      }
    });
  }

  async handleOpenGroupTemplate() {
    if (this.activeGroupLevelAttributes.length) {
      const dialogElem: Vue = this.$buefy.dialog.confirm({
        message: `<p class="buefy-dialog-title">Use Existing Template?</p><p class="buefy-dialog-content">You are trying to use existing template. This action will remove all the current attributes and add new attributes to the group</p>`,
        // confirm and cancel inverted for button styling purposes
        confirmText: 'Cancel',
        onConfirm: () => undefined,
        canCancel: ['button'],
        cancelText: 'Continue',
        onCancel: () => this.openGroupTemplateModal()
      });

      while (!dialogElem.$el?.querySelector) {
        await delay(50);
      }
      const cancelBtn = dialogElem.$el?.querySelector?.(
        `.modal-card > footer > button:first-child`
      );
      cancelBtn?.classList?.add?.(`is-red`);
    } else {
      this.openGroupTemplateModal();
    }
  }

  openGroupTemplateModal() {
    this.$buefy.modal.open({
      parent: this,
      component: UseExistingGroupLevelAttributeTemplateModal,
      hasModalCard: true, // added to prevent breaking on mobile
      trapFocus: true,
      fullScreen: true,
      events: {
        addGroupTemplateAttributes: this.clearAndAddAttributes,
        updateTemplateName: (templateName: string) => {
          Toast.open({
            queue: true,
            type: 'is-dark',
            position: 'is-top',
            message: `${templateName} added`
          });
        }
      }
    });
  }

  /* Method to replace active attributes with group template attributes */
  clearAndAddAttributes(groupTemplateAttributes: GroupLevelAttributeValue[]) {
    groupTemplateAttributes = clone(groupTemplateAttributes);
    this.clearActiveAttributes();

    groupTemplateAttributes.forEach((attribute) => {
      this.activeGroupLevelAttributes.push(attribute);
    });
    this.updateOrderingAndParentValue();
  }

  /* Moves all active attributes to deletion array */
  clearActiveAttributes() {
    const clonedToDelete: GroupLevelAttributeValue[] = clone(
      this.activeGroupLevelAttributes
    );
    clonedToDelete.forEach((attributeToDelete) => {
      this.assignDeleteValue(attributeToDelete);
    });

    /* Separate operation to avoid splicing in for loops */
    this.activeGroupLevelAttributes.length = 0;
    this.deleteGroupLevelAttributes.push(...clonedToDelete);
    this.updateOrderingAndParentValue();
  }

  /* Set attribute value for deletion */
  assignDeleteValue(attributeValue: GroupLevelAttributeValue) {
    attributeValue.groupLevelAttributeSpec.order = 0;
    attributeValue.mapStatus = GroupLevelAttributeValueStatus.DELETE;
    attributeValue.hasFieldError = false;
    attributeValue.hasDuplicationError = false;
  }

  /*
   * Update ordering of active attributes, then emit value.
   * Ordering should increment from default attributes.
   */
  updateOrderingAndParentValue() {
    this.activeGroupLevelAttributes.forEach((attribute, index) => {
      attribute.groupLevelAttributeSpec.order =
        index + 1 + this.defaultGroupLevelAttributes.length;
    });
    this.updateParentValue();
  }

  /*
   * This method checks for the three possible errors in each field.
   * (ie label error, value error, duplication error)
   *
   * Note: DO NOT convert this method into a getter.
   * Getter gives inaccurate result.
   */
  getGroupLevelAttributeError(): boolean {
    const allAttributes: GroupLevelAttributeValue[] = [
      ...this.defaultGroupLevelAttributes,
      ...this.activeGroupLevelAttributes
    ];

    if (allAttributes.length) {
      const labelError: boolean = allAttributes.some(
        (attrVal) => attrVal.label === ''
      );

      const valueError: boolean = allAttributes.some(
        (attrVal) => attrVal.hasFieldError
      );

      const duplicationError: boolean = allAttributes.some(
        (attrVal) => attrVal.hasDuplicationError
      );

      if (
        !labelError &&
        !valueError &&
        !duplicationError &&
        !this.templateNameError
      ) {
        return false;
      }
      return (
        labelError || valueError || duplicationError || this.templateNameError
      );
    }
    return false;
  }

  updateParentValue() {
    this.$emit(
      'updateValuesAndError',
      [...this.defaultGroupLevelAttributes, ...this.activeGroupLevelAttributes],
      this.deleteGroupLevelAttributes,
      this.getGroupLevelAttributeError()
    );
  }

  /**
   * Transforms group level attributes into group level attribute values, with optional default
   * specification handling and sorting by attribute spec order.
   * @param groupLevelAttributes Group level attributes to transform
   * @param isDefaultGLAs Flag to determine if default specifications/sorting should be applied
   */
  transformGroupLevelAttributePayload(
    groupLevelAttributes: GroupLevelAttribute[],
    isDefaultGLAs: boolean = false
  ): GroupLevelAttributeValue[] {
    const transformedItems: GroupLevelAttributeValue[] = groupLevelAttributes.map(
      (groupLevelAttribute) => {
        const baseSpecPayload: GroupLevelAttributeSpec = {
          order: 0,
          isRequired: false,
          isDefault: false
        };

        const defaultSpecPayload: GroupLevelAttributeSpec = {
          ...baseSpecPayload,
          order: isTitleAttribute(groupLevelAttribute) ? 1 : 2,
          isRequired: isTitleAttribute(groupLevelAttribute),
          isDefault: true
        };

        return {
          id: Number(groupLevelAttribute.id),
          label: groupLevelAttribute.label,
          option: groupLevelAttribute.option,
          attributeStatus: groupLevelAttribute.attributeStatus,
          groupLevelAttributeType: groupLevelAttribute.groupLevelAttributeType,
          groupLevelAttributeSpec: isDefaultGLAs
            ? defaultSpecPayload
            : baseSpecPayload,
          value: groupLevelAttribute.option,
          mapStatus: GroupLevelAttributeValueStatus.ACTIVE,
          isTitle: isTitleAttribute(groupLevelAttribute),
          isDisabled: false,
          hasDuplicationError: false,
          hasFieldError: !isLogoAttribute(groupLevelAttribute)
        };
      }
    );

    // Only sort for default GLAs, other transformed items should be sorted by parent component
    if (isDefaultGLAs) {
      return transformedItems.sort(
        (a, b) =>
          (a.groupLevelAttributeSpec.order ?? 0) -
          (b.groupLevelAttributeSpec.order ?? 0)
      );
    }
    return transformedItems;
  }

  @isTruthy
  @Watch('isGroupLevelAttributeTabOpened')
  onTabChange(isAdminOnGroupLevelAttributeTab: boolean) {
    if (isAdminOnGroupLevelAttributeTab && this.isSettings) {
      // fetch only when admin is on the right tab
      this.getGroupLevelAttributeValuesByGroupId(this.groupId);
    }
  }

  @Watch('getDefaultGroupLevelAttributesApiState')
  onGetDefaultGroupLevelAttributeStateChange(state: ApiState) {
    if (state.success) {
      this.defaultGroupLevelAttributes = this.transformGroupLevelAttributePayload(
        this.defaultGLAs,
        true
      );
    }

    if (state.error) {
      Toast.open({
        message: (state.error as AxiosError).response?.data.message,
        type: 'is-danger',
        position: 'is-top'
      });
    }
  }

  @Watch('getGroupLevelAttributeTypesState')
  onGetGroupLevelAttributeTypesStateSuccess(apiState: ApiState) {
    if (apiState.success) {
      this.availableAttributeTypes = clone(
        this.allAttributeTypes
      ) as GroupLevelAttributeType[];
    }
  }

  @isTruthy
  @Watch('getGroupLevelAttributeValuesByGroupIdState.success')
  onGetGroupLevelAttributeValuesByGroupIdStateSuccess() {
    if (this.currentGroupAttributeValues.length === 0) {
      return;
    }

    this.selectedGroupAttributeValues = clone(
      this.currentGroupAttributeValues
    ) as GroupLevelAttributeValue[];

    /*
     * After fetch success, massage attribute data.
     * isTitle, isDefault are not stored in database.
     * They are only used for UI rendering only.
     *
     * At the moment, only "title" and "logo" are default attributes.
     */
    this.selectedGroupAttributeValues.forEach(
      (attrVal: GroupLevelAttributeValue) => {
        /* Value from db are by default without any error until validation runs. */
        attrVal.hasFieldError = false;
        attrVal.hasDuplicationError = false;
        attrVal.isTitle = isTitleAttribute(attrVal);

        // If attribute is default, assign values to optional properties (value, mapId, specId)
        if (isDefaultGroupAttribute(attrVal)) {
          const defaultAttributeIndex = this.defaultGroupLevelAttributes.findIndex(
            (defaultAttr) =>
              defaultAttr.groupLevelAttributeType.type ===
              attrVal.groupLevelAttributeType.type
          );

          // Assign value, map ID, and spec ID to default GLAs
          if (defaultAttributeIndex !== -1) {
            this.defaultGroupLevelAttributes[defaultAttributeIndex].value =
              attrVal.value;
            this.defaultGroupLevelAttributes[
              defaultAttributeIndex
            ].mapId = Number(attrVal.mapId);
            this.defaultGroupLevelAttributes[
              defaultAttributeIndex
            ].groupLevelAttributeSpec.id = Number(
              attrVal.groupLevelAttributeSpec.id
            );
          }
        }
      }
    );

    /*
     * When all attribute data massaging is done,
     * push attributes into active array.
     */
    this.selectedGroupAttributeValues.forEach((attribute, index) => {
      if (!isDefaultGroupAttribute(attribute)) {
        /**
         * Mark attribute as disabled if it's existing and user has no permission
         * to edit existing attributes.
         *
         * Newly added attributes can still be edited.
         */
        this.activeGroupLevelAttributes.push({
          ...attribute,
          isDisabled:
            'id' in attribute &&
            !this.isUserAllowed(
              PermissionsMatrixActionsEnum.UPDATE,
              'group_administration-groups-update_groups-update_group_attributes-update_existing_attributes',
              true
            )
        });
      }
    });
  }

  @Watch('verifyGroupLevelAttributeTemplateNameApiState', {
    deep: true,
    immediate: true
  })
  verifyTemplateNameApiStateCallback(verifyState: ApiState): void {
    if (verifyState.success) {
      if (this.templateName === '') {
        return;
      }

      if (this.isDuplicateName) {
        this.templateNameError = true;
        this.templateNameErrorMsg = `Template with the name ${this.templateName} already exists.`;
        this.templateNameMsgType = 'is-danger';
        this.updateParentValue();
        return;
      }

      this.templateNameError = false;
      this.templateNameErrorMsg = 'Template name is available.';
      this.templateNameMsgType = 'is-success';
    }

    if (verifyState.error) {
      this.templateNameError = true;
      this.templateNameErrorMsg = verifyState.error.message;
      this.templateNameMsgType = 'is-danger';
    }

    this.updateParentValue();
  }

  @Watch('saveAsTemplate')
  onCheckboxTriggered() {
    if (!this.saveAsTemplate) {
      this.templateName = '';
      this.clearNameFieldAttr();
      this.updateParentValue();
      return;
    }

    if (this.templateName === '') {
      this.templateNameError = true;
      this.templateNameErrorMsg = 'Template name is empty.';
      this.templateNameMsgType = 'is-danger';
      this.updateParentValue();
    }
  }

  @Watch('templateName')
  onTemplateNameGiven() {
    if (this.templateName) {
      this.$emit('templateDetails', this.saveAsTemplate, this.templateName);
    }
  }
}
