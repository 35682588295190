


















import { Vue, Component, Prop } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import { GroupLevelAttributeValue } from '@/jbi-shared/types/jaas-group-level-attributes.types';

@Component({})
export default class FieldWrapper extends Vue {
  @Prop({ type: Function, required: true })
  public component!: VueConstructor;

  get groupLevelAttributeProps() {
    return {
      ...this.$props,
      ...this.$attrs,
      // generic functions
      // to prompt parent to update values in children
      updateAttributeValue: (attribute: GroupLevelAttributeValue) => {
        this.$emit('updateAttributeValue', attribute);
      },

      editAttribute: (attribute: GroupLevelAttributeValue) => {
        this.$emit('editAttribute', attribute);
      },

      deleteAttribute: () => {
        this.$emit('deleteAttribute');
      },

      toggleAttributeRequire(attributeValue: GroupLevelAttributeValue): void {
        attributeValue.groupLevelAttributeSpec.isRequired = !attributeValue
          .groupLevelAttributeSpec.isRequired;
      }
    };
  }
}
