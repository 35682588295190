var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card has-modal-large"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Edit Attribute")]),_c('button',{staticClass:"close-icon",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{staticClass:"modal-card-body",class:_vm.isDuplicateAttributeFound ? _vm.$style.modalCardBody : ''},[(_vm.getGroupLevelAttributeByIdApiState.loading)?_c('BaseLoading'):_c('div',[_c('b-field',{attrs:{"label":"Content Type"}},[_c('b-dropdown',{attrs:{"expanded":"","aria-role":"list","disabled":true},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_vm._t("customTrigger",[_c('div',{class:_vm.$style.trigger},[_c('div',{class:_vm.$style.text},[_c('span',{class:_vm.$style.capitalize},[_vm._v(" "+_vm._s(_vm.type)+" ")])]),_c('b-icon',{attrs:{"icon":active ? 'chevron-up' : 'chevron-down'}})],1)])]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Label","message":_vm.duplicationErrorMsg,"type":_vm.isActualDuplicateAndNotOriginalState ? 'is-warning' : ''}},[_c('b-input',{attrs:{"placeholder":"Attribute Label Name"},model:{value:(_vm.updatedLabel),callback:function ($$v) {_vm.updatedLabel=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"updatedLabel"}})],1),(_vm.isActualDuplicateAndNotOriginalState)?_c('div',{staticClass:"columns is-centered",class:_vm.$style.duplicateLabel},[_c('div',{staticClass:"column is-three-quarters p-3"},[_vm._v(" An attribute with the same configuration already exists. Would you like to proceed with creating an attribute with same configuration? ")]),_c('div',{staticClass:"column has-text-primary",class:_vm.$style.actionBtn,on:{"click":_vm.onProceed}},[_vm._v(" Proceed ")])]):_vm._e(),(_vm.attributeOptionsAsOptionType)?_c('div',[(_vm.type === 'address' || _vm.type === 'coordinates')?_c('div',{class:_vm.$style.attrOptions},_vm._l((_vm.attributeOptionsAsOptionType),function(configObj,confIndex){return _c('b-field',{key:confIndex,class:_vm.$style.paddingRight},[_c('b-switch',{class:_vm.$style.switch,attrs:{"left-label":true,"false-value":'DELETE',"true-value":'ACTIVE',"disabled":configObj.isRequired},model:{value:(configObj.status),callback:function ($$v) {_vm.$set(configObj, "status", $$v)},expression:"configObj.status"}},[_vm._v(" "+_vm._s(configObj.placeholder)+" ")])],1)}),1):_vm._e()]):_vm._e(),(
          this.type === 'list' && this.option && _vm.attributeOptionsAsListType
        )?_c('div',{class:_vm.$style.listOptions},[_c('div',{class:_vm.$style.selectionType},[_c('b-field',[_c('b-radio',{attrs:{"native-value":true},model:{value:(_vm.attributeOptionsAsListType.isSingleSelection),callback:function ($$v) {_vm.$set(_vm.attributeOptionsAsListType, "isSingleSelection", $$v)},expression:"attributeOptionsAsListType.isSingleSelection"}},[_vm._v(" Single selection ")])],1),_c('b-field',[_c('b-radio',{attrs:{"native-value":false},model:{value:(_vm.attributeOptionsAsListType.isSingleSelection),callback:function ($$v) {_vm.$set(_vm.attributeOptionsAsListType, "isSingleSelection", $$v)},expression:"attributeOptionsAsListType.isSingleSelection"}},[_vm._v(" Multiple selections ")])],1)],1),_c('div',[_c('label',{class:_vm.$style.label},[_vm._v("List options")]),_vm._l((_vm.attributeOptionValues),function(item,index){return _c('li',{key:index,class:_vm.$style.optionlistItem,on:{"click":function($event){return _vm.removeOptionListItem(
                $event,
                _vm.$style.optionlistItemDeleteBtn,
                index
              )}}},[_c('b-icon',{class:_vm.$style.optionlistItemSquareBox,attrs:{"icon":"square-outline","size":"is-small"}}),_c('b-field',{class:_vm.$style.field,staticStyle:{"width":"100%","margin-bottom":"5px"},attrs:{"type":_vm.attributeOptionErrorIndexes.includes(index)
                  ? 'is-danger'
                  : undefined,"message":_vm.attributeOptionErrorIndexes.includes(index)
                  ? 'This option already exists.'
                  : ''}},[_c('b-input',{attrs:{"placeholder":""},on:{"input":_vm.checkAttributeOptionDuplicateValues},model:{value:(_vm.attributeOptionValues[index]),callback:function ($$v) {_vm.$set(_vm.attributeOptionValues, index, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"attributeOptionValues[index]"}})],1),_c('b-icon',{class:_vm.$style.optionlistItemDeleteBtn,attrs:{"icon":"delete","size":"is-small","type":"is-danger"}})],1)}),_c('div',[_c('b-button',{class:_vm.$style.optionlistItemAddBtn,attrs:{"type":"is-ghost"},on:{"click":_vm.addOptionListItem}},[_vm._v(" + Add item ")])],1)],2)]):_vm._e()],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{class:_vm.$style.pureLabelButton,on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"button is-primary",attrs:{"disabled":_vm.isButtonDisabled()},on:{"click":_vm.updateAttributeConfirmation}},[_vm._v(" Save Changes ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }