






































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DraggableIcon from '../GroupLevelAttributes/DraggableIcon.vue';
import { getHtmlContent } from '@/jbi-shared/util/group-level-attributes.util';
import { GroupLevelAttributeValue } from '@/jbi-shared/types/jaas-group-level-attributes.types';
import AttributeActionButtons from './AttributeActionButtons.vue';

@Component({
  components: {
    DraggableIcon,
    AttributeActionButtons
  }
})
export default class GroupLevelAttributeTextField extends Vue {
  @Prop() attributeValue!: GroupLevelAttributeValue;
  @Prop() fieldClasses!: any;
  @Prop() duplicationError!: boolean;
  @Prop() deleteAttribute!: () => void;
  @Prop() updateAttributeValue!: (attribute: GroupLevelAttributeValue) => void;
  @Prop() toggleAttributeRequire!: (
    attribute: GroupLevelAttributeValue
  ) => void;
  @Prop() editAttribute!: (attribute: GroupLevelAttributeValue) => void;

  fieldErrorMsg: string = '';

  mounted() {
    this.validateRequiredFieldValues();

    /**
     * Workaround to override Quill's default link field placeholder in tooltip
     * https://github.com/quilljs/quill/issues/1107#issuecomment-259938173
     */
    const linkTooltip = document.querySelector('.ql-snow .ql-tooltip');
    const urlInput = (linkTooltip as any).querySelector('input[data-link]');

    if (urlInput) {
      urlInput.dataset.link = 'URL with https://';
    }
  }

  // Computed property so changes from the value from parent component are synced
  get isRequired() {
    return this.attributeValue.groupLevelAttributeSpec.isRequired;
  }

  get editorOption() {
    return {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'link'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }]
        ]
      },
      placeholder: 'Ctrl/Cmd + Shift + V to paste without formatting'
    };
  }

  /**
   * Add a separate watcher for "hasDuplicationError" property
   * because this property is updated by parent.
   */
  @Watch('duplicationError')
  duplicationCallback(currentError: boolean, previousError: boolean): void {
    this.updateAttributeValue(this.attributeValue);
  }

  @Watch('attributeValue.value')
  @Watch('isRequired', { immediate: true })
  validateRequiredFieldValues() {
    // validate null / empty values for required fields
    if (
      this.attributeValue.groupLevelAttributeSpec.isRequired &&
      getHtmlContent(
        this.attributeValue.value ? (this.attributeValue.value as string) : ''
      ).length === 0
    ) {
      this.fieldErrorMsg = 'Required field cannot be empty.';
      this.attributeValue.hasFieldError = true;
      this.updateAttributeValue(this.attributeValue);
      return;
    }

    this.fieldErrorMsg = '';
    this.attributeValue.hasFieldError = false;
    this.updateAttributeValue(this.attributeValue);
  }
}
