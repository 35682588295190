












































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseTable from '@/components/BaseTable.vue';
import {
  GroupLevelAttributeListType,
  GroupLevelAttributeOption,
  GroupLevelAttributeTemplate,
  GroupLevelAttributeWithSpec
} from '@/jbi-shared/types/jaas-group-level-attributes.types';
import { toCapitalCase } from '@/jbi-shared/util/string.utils';

@Component({
  components: {
    BaseTable
  }
})
export default class TemplateView extends Vue {
  @Prop() glaTemplate!: GroupLevelAttributeTemplate;

  defaultListTypeOption: GroupLevelAttributeListType = {
    options: [],
    selected: [],
    isSingleSelection: false
  };

  attributeOptionsForListGLA(attribute: GroupLevelAttributeWithSpec) {
    // Account for missing list type object
    return (
      (attribute.option as GroupLevelAttributeListType) ??
      this.defaultListTypeOption
    );
  }

  attributeOptionsForAddressGLA(attribute: GroupLevelAttributeWithSpec) {
    if (!(attribute.option as GroupLevelAttributeOption[])) {
      return [];
    }
    // Account for missing address type object and only use active address lines
    return (attribute.option as GroupLevelAttributeOption[]).filter(
      (addressLine) => addressLine.status === 'ACTIVE'
    );
  }

  convertToCapitalCase(str: string): string {
    // Utilize helper method from shared repo
    return toCapitalCase(str);
  }

  closeModal() {
    this.$emit('close');
  }
}
