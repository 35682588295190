var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.isUserAllowed(
      _vm.PermissionsMatrixActionsEnum.READ,
      'group_administration-groups-read_groups-read_groups_attributes',
      true
    )
  )?_c('div',[_c('div',{class:_vm.$style.buttonControl},[(
        _vm.isUserAllowed(
          _vm.PermissionsMatrixActionsEnum.READ,
          'group_administration-groups-update_groups-update_group_attributes-read_existing_group_templates'
        )
      )?_c('b-button',{staticClass:"is-square",on:{"click":_vm.handleOpenGroupTemplate}},[_vm._v(" Use existing template ")]):_vm._e(),(
        _vm.isUserAllowed(
          _vm.PermissionsMatrixActionsEnum.UPDATE,
          'group_administration-groups-update_groups-update_group_attributes-create_group_attributes',
          true
        )
      )?_c('b-button',{staticClass:"is-square is-primary",attrs:{"disabled":_vm.getGroupLevelAttributeTypesState.loading},on:{"click":_vm.openAddGroupLevelAttributeModal}},[_vm._v(" Add new attribute ")]):_vm._e()],1),(
      this.defaultGroupLevelAttributes.concat( this.activeGroupLevelAttributes
      ).length === 0
    )?_c('div',[(
        _vm.getGroupLevelAttributeValuesByGroupIdState.loading ||
        _vm.getGroupLevelAttributeTypesState.loading ||
        _vm.getDefaultGroupLevelAttributesApiState.loading
      )?_c('h1',{staticClass:"has-text-centered is-italic"},[_vm._v(" Fetching attribute values... ")]):_c('h1',{staticClass:"has-text-centered"},[_vm._v("No attributes yet.")])]):_c('div',[(
        _vm.isUserAllowed(
          _vm.PermissionsMatrixActionsEnum.UPDATE,
          'group_administration-groups-update_groups-update_group_attributes-update_default_attributes',
          true
        )
      )?_c('div',[_c('FieldWrapper',{class:_vm.$style.defaultAttributes,attrs:{"groupName":_vm.syncedGroupName,"isSettings":_vm.isSettings,"groupId":_vm.groupId,"groupTypeName":_vm.groupTypeName,"component":_vm.getTitleAttributeFieldComponent(),"attributeValue":_vm.defaultGroupLevelAttributes[0]},on:{"update:groupName":function($event){_vm.syncedGroupName=$event},"update:group-name":function($event){_vm.syncedGroupName=$event},"updateAttributeValue":_vm.handleUpdateAttributeValue}}),_c('FieldWrapper',{class:_vm.$style.defaultAttributes,attrs:{"isSettings":_vm.isSettings,"groupId":_vm.groupId,"groupTypeName":_vm.groupTypeName,"component":_vm.getLogoAttributeFieldComponent(),"attributeValue":_vm.defaultGroupLevelAttributes[1]},on:{"updateAttributeValue":_vm.handleUpdateAttributeValue}})],1):_vm._e(),_c('draggable',{key:_vm.activeGroupLevelAttributes.length +
        '_' +
        _vm.deleteGroupLevelAttributes.length,attrs:{"list":_vm.activeGroupLevelAttributes,"handle":".handle"},on:{"change":_vm.updateOrderingAndParentValue}},_vm._l((_vm.activeGroupLevelAttributes),function(groupLevelAttr,groupLevelAttrIndex){return _c('div',{key:groupLevelAttrIndex},[_c('FieldWrapper',{key:groupLevelAttr.id + '_' + groupLevelAttrIndex,class:_vm.$style.attributes,attrs:{"component":_vm.getFieldComponent(groupLevelAttr),"attributeValue":groupLevelAttr,"duplicationError":groupLevelAttr.hasDuplicationError},on:{"updateAttributeValue":_vm.handleUpdateAttributeValue,"editAttribute":function($event){return _vm.openEditAttributeModal(groupLevelAttr, groupLevelAttrIndex)},"deleteAttribute":function($event){return _vm.handleDeleteAttribute(groupLevelAttrIndex)}}})],1)}),0)],1),(!_vm.isSettings && _vm.canCreateNewTemplate)?_c('div',[_c('hr'),_c('b-field',[_c('b-checkbox',{model:{value:(_vm.saveAsTemplate),callback:function ($$v) {_vm.saveAsTemplate=$$v},expression:"saveAsTemplate"}},[_vm._v(" Save attributes as a new template ")])],1),(_vm.saveAsTemplate)?_c('b-field',{attrs:{"label":"Template Name","message":_vm.templateNameErrorMsg,"type":_vm.templateNameMsgType}},[_c('b-input',{attrs:{"type":"text","placeholder":"Enter template name here","loading":_vm.verifyGroupLevelAttributeTemplateNameApiState.loading},on:{"input":_vm.handleTemplateNameChange},model:{value:(_vm.templateName),callback:function ($$v) {_vm.templateName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"templateName"}})],1):_vm._e()],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }