
































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DraggableIcon from './DraggableIcon.vue';
import dayjs from 'dayjs';
import { GroupLevelAttributeValue } from '@/jbi-shared/types/jaas-group-level-attributes.types';
import AttributeActionButtons from './AttributeActionButtons.vue';

@Component({
  components: {
    DraggableIcon,
    AttributeActionButtons
  }
})
export default class GroupLevelAttributeTextField extends Vue {
  @Prop() attributeValue!: GroupLevelAttributeValue;
  @Prop() fieldClasses!: any;
  @Prop() duplicationError!: boolean;
  @Prop() deleteAttribute!: () => void;
  @Prop() updateAttributeValue!: (attribute: GroupLevelAttributeValue) => void;
  @Prop() toggleAttributeRequire!: (
    attribute: GroupLevelAttributeValue
  ) => void;
  @Prop() editAttribute!: (attribute: GroupLevelAttributeValue) => void;

  fieldErrorMsg: string = '';
  dateValue: Date | null = null;

  // Computed property so changes from the value from parent component are synced
  get isRequired() {
    return this.attributeValue.groupLevelAttributeSpec.isRequired;
  }

  mounted() {
    this.validateRequiredFieldValues();

    if (this.attributeValue.value) {
      this.dateValue = dayjs(this.attributeValue.value as string).toDate();
    }
  }

  clearDate() {
    this.dateValue = null;
    this.attributeValue.value = null;
  }

  formatDate(date: Date) {
    this.dateValue = dayjs(date).toDate();
  }

  displayDate(): string {
    return dayjs(this.dateValue as Date).format('DD MMM YYYY');
  }

  @Watch('dateValue')
  assignDateValue() {
    this.attributeValue.value = this.dateValue
      ? dayjs(this.dateValue).toISOString()
      : null;
  }

  /**
   * Add a separate watcher for "hasDuplicationError" property
   * because this property is updated by parent.
   */
  @Watch('duplicationError')
  duplicationCallback(currentError: boolean, previousError: boolean): void {
    this.updateAttributeValue(this.attributeValue);
  }

  @Watch('attributeValue.value')
  @Watch('isRequired', { immediate: true })
  validateRequiredFieldValues() {
    if (
      this.attributeValue.groupLevelAttributeSpec.isRequired &&
      !this.attributeValue.value
    ) {
      this.fieldErrorMsg = 'Required field cannot be empty.';
      this.attributeValue.hasFieldError = true;
      this.updateAttributeValue(this.attributeValue);
      return;
    }

    this.fieldErrorMsg = '';
    this.attributeValue.hasFieldError = false;
    this.updateAttributeValue(this.attributeValue);
  }
}
