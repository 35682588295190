



















































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import { CreateGroupLevelAttributeResponse } from '../../../../store/modules/admin/types/group-level-attribute.types';
import {
  AttributeOptionType,
  GroupLevelAttributeListType,
  GroupLevelAttributeOption,
  GroupLevelAttributeStatus,
  GroupLevelAttributeType,
  GroupLevelAttributeWithSpec
} from '@/jbi-shared/types/jaas-group-level-attributes.types';
import { Action, State } from 'vuex-class';
import { CreateGroupLevelAttributeDTO } from '@/jbi-shared/dto/jaas-group-level-attributes.dtos';
import { RootState } from '@/store/store';
import { ApiState } from '@/store/types/general.types';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { AxiosError } from 'axios';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { isDefaultGroupAttribute } from '@/jbi-shared/util/group-level-attributes.util';

@Component({})
export default class CreateNewGroupLevelAttributeModal extends Vue {
  @Prop() allAttributeTypes!: GroupLevelAttributeType[];
  @Prop() groupId!: number;
  @Prop() isTemplateAttribute!: boolean;

  defaultAttributeType: GroupLevelAttributeType = {
    id: 0,
    type: '',
    option: null
  };
  optionPayload: AttributeOptionType = null;
  isRequired: boolean = false;
  selectedAttributeType: GroupLevelAttributeType = this.defaultAttributeType;
  attributeLabelName: string = '';
  duplicationError: boolean = false;
  duplicationErrorMsg: string = '';
  isSingleSelection: boolean = true;
  attributeOptionValues: string[] = Array(2);
  attributeOptionErrorIndexes: number[] = [];
  groupLevelAttributeTemplateWithSpecs: GroupLevelAttributeWithSpec | null = null;

  disableSave: boolean = true;
  overrideDuplicateCheck: boolean = false;

  @Action('admin/createGroupLevelAttribute')
  public createGroupLevelAttribute!: (
    payload: CreateGroupLevelAttributeDTO
  ) => void;

  @State((state: RootState) => state.admin.newGroupLevelAttribute)
  public newGroupLevelAttribute!: CreateGroupLevelAttributeResponse;

  @State((state: RootState) => state.admin.apiState.createGroupLevelAttribute)
  public createGroupLevelAttributeApiState!: ApiState;

  @Action('admin/verifyGroupLevelAttribute')
  public verifyGroupLevelAttribute!: (
    payload: CreateGroupLevelAttributeDTO
  ) => void;

  @State((state: RootState) => state.admin.verifyGroupLevelAttribute)
  public getVerifyGroupLevelAttribute!: boolean;

  @State(
    (state: RootState) => state.admin.apiState.verifyGroupLevelAttribute.success
  )
  public getVerifyGroupLevelAttributeSuccess!: boolean;

  get isDefault(): boolean {
    return isDefaultGroupAttribute({
      label: this.attributeLabelName,
      groupLevelAttributeType: this.selectedAttributeType,
      option: this.optionPayload,
      attributeStatus: GroupLevelAttributeStatus.ACTIVE
    });
  }

  get attributeOptionsAsOptionType() {
    return this.selectedAttributeType.option as GroupLevelAttributeOption[];
  }

  /**
   * Admins should still be able to create duplicate GLAs. So, the duplicate check popup serves
   * as a soft reminder that prompts admins into making a conscious decision that duplicates
   * are being added. Admins can decide on whether to discard/proceed with creating the duplicate
   * GLA. This method overrides the duplicate check.
   */
  onProceed(): void {
    this.disableSave = false;
    this.duplicationError = false;
    this.overrideDuplicateCheck = true;
  }

  @Debounce(300)
  validateDuplicate() {
    if (
      this.attributeLabelName.length >= 1 &&
      this.selectedAttributeType.id !== 0
    ) {
      if (this.isDefault) {
        this.duplicationErrorMsg = `'${this.attributeLabelName}' is a default attribute. Please use a different type or label name.`;
        this.duplicationError = true;
        return;
      }

      // Only perform check if option values are populated
      if (
        this.selectedAttributeType.type === 'list' &&
        this.attributeOptionValues.length === 0
      ) {
        return;
      }

      this.constructAttributeOptionPayload();
      this.verifyGroupLevelAttribute({
        labelName: this.attributeLabelName,
        groupLevelAttributeTypeId: this.selectedAttributeType.id,
        option: this.optionPayload
      });
      this.duplicationErrorMsg = '';
      this.duplicationError = false;
    } else {
      this.duplicationError = false;
    }
  }

  /**
   * Determines if the 'create' button should be disabled based on form validation rules.
   *
   * Button is disabled when:
   * 1. Invalid payload conditions:
   *    - Empty attribute label
   *    - Duplicate attribute detected
   *    - Duplicate group level attribute status unknown
   *    - General save disabled flag
   * 2. Form is in its original unchanged state
   *
   * Additional validation for list-type attributes:
   *    - Must have at least 2 valid options
   *    - No validation errors in option entries
   */
  isButtonDisabled(): boolean {
    const isInvalidLabel: boolean =
      this.attributeLabelName === '' ||
      this.duplicationError ||
      this.getVerifyGroupLevelAttribute === undefined ||
      this.disableSave;

    if (this.selectedAttributeType.type === 'list') {
      return (
        isInvalidLabel ||
        this.attributeOptionErrorIndexes.length > 0 ||
        // Must have at least 2 options
        !(this.getAttributeOptionValues().length > 1)
      );
    }

    return isInvalidLabel || this.selectedAttributeType.type === '';
  }

  removeOptionListItem(event: any, identifierClass: string, itemIndex: number) {
    if (event.target.parentElement.className.includes(identifierClass)) {
      this.attributeOptionValues.splice(itemIndex, 1);
      this.checkAttributeOptionDuplicateValues();
    }
  }

  addOptionListItem() {
    this.attributeOptionValues.push('');
  }

  getAttributeOptionValues() {
    return this.attributeOptionValues.filter((value) => value.length);
  }

  checkAttributeOptionDuplicateValues() {
    this.attributeOptionErrorIndexes = [];
    this.attributeOptionValues.forEach((item, index) => {
      if (item.trim() && this.attributeOptionValues.indexOf(item) !== index) {
        this.attributeOptionErrorIndexes.push(index);
      }
    });
  }

  handleNewAttributeCreation() {
    this.createAttribute(this.optionPayload);
  }

  createAttribute(constructedOption: AttributeOptionType) {
    // Construct the payload
    const newGroupLevelAttribute: CreateGroupLevelAttributeDTO = {
      labelName: this.attributeLabelName,
      groupLevelAttributeTypeId: this.selectedAttributeType.id,
      option: constructedOption,
      overrideDuplicateCheck: this.overrideDuplicateCheck
    };

    // Make the API call
    this.createGroupLevelAttribute(newGroupLevelAttribute);
  }

  constructAttributeOptionPayload() {
    // Account for different payload types
    switch (this.selectedAttributeType.type) {
      case 'list':
        const listTypeOption: GroupLevelAttributeListType = {
          options: this.getAttributeOptionValues(),
          selected: this.isSingleSelection ? '' : [],
          isSingleSelection: this.isSingleSelection
        };
        this.optionPayload = listTypeOption;
        break;
      case 'address':
      case 'coordinates':
        const glaOption: GroupLevelAttributeOption[] = (this
          .selectedAttributeType.option as GroupLevelAttributeOption[]).map(
          (option) => ({
            placeholder: option.placeholder,
            status: option.status,
            attributeType: option.attributeType,
            value: '',
            isRequired: option.isRequired
          })
        );
        this.optionPayload = glaOption;
        break;
      default:
        // documents, images, text, text area GLA types have null options.
        this.optionPayload = null;
        break;
    }
  }

  closeModal() {
    this.$emit('close');
  }

  resetFields() {
    this.duplicationError = false;
    this.isRequired = false;
    this.attributeLabelName = '';
    this.selectedAttributeType = this.defaultAttributeType;
    this.isSingleSelection = false;
    this.attributeOptionValues = Array(2);
    this.groupLevelAttributeTemplateWithSpecs = null;
  }

  @Watch('createGroupLevelAttributeApiState', { deep: true })
  onCreateGroupLevelAttributeApiStateChange(state: ApiState) {
    if (state.success) {
      Toast.open({
        message: 'Group Level Attribute Created Successfully.',
        type: 'is-dark',
        position: 'is-top'
      });
      const { createdGroupLevelAttribute } = this.newGroupLevelAttribute;

      this.groupLevelAttributeTemplateWithSpecs = {
        id: createdGroupLevelAttribute.id!,
        groupLevelAttributeType:
          createdGroupLevelAttribute.groupLevelAttributeType,
        label: createdGroupLevelAttribute.label,
        option: createdGroupLevelAttribute.option,
        // Assign default status
        attributeStatus: createdGroupLevelAttribute.attributeStatus,
        groupLevelAttributeSpec: {
          isRequired: this.isRequired,
          isDefault: false,
          // Assign default order; parent component will handle attribute reordering
          order: 0
        }
      };

      this.$emit('addNewAttribute', this.groupLevelAttributeTemplateWithSpecs);
      this.resetFields();
      this.closeModal();
    }

    if (state.error) {
      Toast.open({
        message: (state.error as AxiosError).response?.data.message,
        type: 'is-danger',
        position: 'is-top'
      });

      this.resetFields();
    }
  }

  // Generic watching for all fields to prompt validation
  @Watch('attributeLabelName')
  @Watch('selectedAttributeType')
  @Watch('attributeOptionValues', { deep: true })
  public onFieldsUpdated() {
    this.duplicationError = false; // clear debounced state
    this.disableSave = true;
    this.validateDuplicate();
  }

  @Watch('getVerifyGroupLevelAttributeSuccess')
  @isTruthy
  public watchVerifyGroupLevelAttributeSuccess() {
    if (this.getVerifyGroupLevelAttribute) {
      this.duplicationError = true;
    } else {
      this.onProceed();
    }
  }
}
