






































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { FilteredGroupTemplatesPayload } from '../../../../store/modules/admin/types/group-level-attribute.types';
import { RootState } from '../../../../store/store';
import { Debounce } from '../../../../jbi-shared/util/debounce.vue-decorator';
import { Pagination } from 'nestjs-typeorm-paginate';
import { SortOrder } from '@/store/types/general.types';
import {
  AttributeValueType,
  GroupLevelAttributeContactsType,
  GroupLevelAttributeListType,
  GroupLevelAttributeOption,
  GroupLevelAttributeTemplate,
  GroupLevelAttributeValue,
  GroupLevelAttributeValueStatus,
  GroupLevelAttributeWithSpec
} from '@/jbi-shared/types/jaas-group-level-attributes.types';
import BaseLoading from '@/components/base/BaseLoading.vue';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import GroupLevelAttributeTemplateSelectionList from './GroupLevelAttributeTemplateSelectionList.vue';

/**
 * Modal to use render a list of existing group level attribute templates.
 * User can select a template via radio button and click on 'Use Template' button.
 * This component is used explicitly in  `UseExistingGroupLevelAttributeTemplateModal.vue`
 */
@Component({
  components: {
    BasePaginatorHoc,
    GroupLevelAttributeTemplateSelectionList,
    BaseLoading
  }
})
export default class UseExistingGroupLevelAttributeTemplateModal extends Vue {
  public perPage = 50;
  public page = 1;
  public sortColumn: string = 'templateName';
  public sortOrder: SortOrder = SortOrder.ASC;
  filteredParams = '';
  selectedTemplateName: string = '';

  public allSelectedAttributes: GroupLevelAttributeValue[] = [];

  @Action('admin/getGroupLevelAttributeTemplates')
  getGroupLevelAttributeTemplates!: (
    options: FilteredGroupTemplatesPayload
  ) => void;

  @State(
    ({ admin }: RootState) =>
      admin.apiState.getGroupLevelAttributeTemplates.success
  )
  getGroupLevelAttributeTemplatesSuccess!: boolean;

  @State(({ admin }: RootState) => admin.groupLevelAttributeTemplates)
  groupLevelAttributeTemplates!: Pagination<GroupLevelAttributeTemplate>;

  get GroupLevelAttributeTemplateSelectionList() {
    return GroupLevelAttributeTemplateSelectionList;
  }

  get templates(): GroupLevelAttributeTemplate[] {
    return this.groupLevelAttributeTemplates
      ? this.groupLevelAttributeTemplates.items
      : [];
  }

  getRouteConfig() {
    return {
      name: 'admin-group-management',
      query: {
        tab: 'GroupTemplates',
        templateName: this.filteredParams,
        limit: this.perPage.toString() || '50',
        page: this.page.toString() || '1',
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder || SortOrder.ASC
      }
    };
  }

  /**
   * Close modal on click.
   * Should there be any template changes, in group management page,
   * it should be reflected when modal opens up again.
   */
  handleLinkClick(): void {
    this.closeModal();
  }

  selectedTemplate(template: GroupLevelAttributeTemplate) {
    this.allSelectedAttributes = template.groupLevelAttributesWithSpecs.map(
      (attr) => {
        return {
          order: 0,
          value: this.getAttributeValueFromOption(attr),
          id: attr.id,
          label: attr.label,
          option: attr.option,
          attributeStatus: attr.attributeStatus,
          mapStatus: GroupLevelAttributeValueStatus.ACTIVE,
          groupLevelAttributeType: attr.groupLevelAttributeType,
          groupLevelAttributeSpec: attr.groupLevelAttributeSpec,
          isRequired: attr.groupLevelAttributeSpec.isRequired,
          hasFieldError: attr.groupLevelAttributeSpec.isRequired,
          hasDuplicationError: false,
          isDefault: false,
          isTitle: false
        };
      }
    );

    this.selectedTemplateName = template.name;
  }

  getAttributeValueFromOption(
    attribute: GroupLevelAttributeWithSpec
  ): AttributeValueType {
    switch (attribute.groupLevelAttributeType.type) {
      case 'address':
      case 'coordinates':
        return attribute.option as GroupLevelAttributeOption[];
      case 'list':
        return attribute.option as GroupLevelAttributeListType;
      case 'contact':
        return attribute.option as GroupLevelAttributeContactsType[];
      case 'documents':
      case 'images':
        return [];
      default:
        // GLAs other than address, coordinates, list have options default to null
        return null;
    }
  }

  isButtonDisabled(): boolean {
    return this.allSelectedAttributes.length ? false : true;
  }

  useTemplate() {
    if (this.allSelectedAttributes.length) {
      this.$emit('addGroupTemplateAttributes', this.allSelectedAttributes);
      this.$emit('updateTemplateName', this.selectedTemplateName);
      this.selectedTemplateName = '';
      this.closeModal();
    }
  }

  public handlePaginator({ perPage, page }: { perPage: number; page: number }) {
    this.perPage = perPage;
    this.page = page;
    this.getGroupLevelAttributeTemplates({
      templateName: this.filteredParams,
      limit: this.perPage,
      page: this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    });
  }

  public handleSort(data: any) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    this.getGroupLevelAttributeTemplates({
      templateName: this.filteredParams,
      limit: this.perPage,
      page: this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    });
  }

  public handleFilter() {
    this.perPage = this.perPage;
    this.getGroupLevelAttributeTemplates({
      templateName: this.filteredParams,
      limit: this.perPage,
      page: 1,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    });
  }

  public mounted() {
    this.getGroupLevelAttributeTemplates({
      templateName: this.filteredParams,
      limit: this.perPage,
      page: 1,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    });
  }

  closeModal() {
    this.$emit('close');
  }

  @Watch('filteredParams')
  @Debounce(600)
  public onChange() {
    this.handleFilter();
  }
}
