








































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DraggableIcon from '../GroupLevelAttributes/DraggableIcon.vue';
import { GroupLevelAttributeValue } from '@/jbi-shared/types/jaas-group-level-attributes.types';
import AttributeActionButtons from './AttributeActionButtons.vue';

@Component({
  components: {
    DraggableIcon,
    AttributeActionButtons
  }
})
export default class GroupLevelAttributeNumberField extends Vue {
  @Prop() attributeValue!: GroupLevelAttributeValue;
  @Prop() fieldClasses!: any;
  @Prop() duplicationError!: boolean;
  @Prop() deleteAttribute!: () => void;
  @Prop() updateAttributeValue!: (attribute: GroupLevelAttributeValue) => void;
  @Prop() toggleAttributeRequire!: (
    attribute: GroupLevelAttributeValue
  ) => void;
  @Prop() editAttribute!: (attribute: GroupLevelAttributeValue) => void;

  fieldErrorMsg: string = '';

  // Computed property so changes from the value from parent component are synced
  get isRequired() {
    return this.attributeValue.groupLevelAttributeSpec.isRequired;
  }

  mounted() {
    this.validateRequiredFieldValues();
  }

  /* Disable up down arrow key press and character 'e' input.
   * This method will only trigger on keydown. */
  validateInteger(event: any) {
    if (event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 69) {
      event.preventDefault();
    }
  }

  /**
   * Add a separate watcher for "hasDuplicationError" property
   * because this property is updated by parent.
   */
  @Watch('duplicationError')
  duplicationCallback(currentError: boolean, previousError: boolean): void {
    this.updateAttributeValue(this.attributeValue);
  }

  @Watch('attributeValue.value')
  @Watch('isRequired', { immediate: true })
  validateRequiredFieldValues() {
    // validate null / empty values for required fields
    if (
      this.attributeValue.groupLevelAttributeSpec.isRequired &&
      (this.attributeValue.value === null || this.attributeValue.value === '')
    ) {
      this.fieldErrorMsg = 'Required field cannot be empty.';
      this.attributeValue.hasFieldError = true;
      this.updateAttributeValue(this.attributeValue);
      return;
    }

    this.fieldErrorMsg = '';
    this.attributeValue.hasFieldError = false;
    this.updateAttributeValue(this.attributeValue);
  }
}
