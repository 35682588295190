






















import TableActionDropdown from '@/components/TableActionDropdown.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    TableActionDropdown
  }
})
export default class AttributeActionButtons extends Vue {
  @Prop() disabled!: boolean;
  @Prop() isRequired!: boolean;

  get shouldDisableEdit() {
    return this.disabled;
  }

  get isRequiredAttribute() {
    return this.isRequired;
  }
}
