




































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { RootState } from '../../../../store/store';
import { ApiState } from '@/store/types/general.types';
import {
  AddressCountry,
  AddressState,
  AddressFilterOption
} from '../../../../store/modules/admin/types/group-level-attribute.types';
import DraggableIcon from '../GroupLevelAttributes/DraggableIcon.vue';
import { ToastProgrammatic as Toast } from 'buefy';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { clone } from '@/jbi-shared/util/group-level-attributes.util';
import {
  GroupLevelAttributeOption,
  GroupLevelAttributeValue,
  GroupLevelAttributeValueStatus
} from '@/jbi-shared/types/jaas-group-level-attributes.types';
import AttributeActionButtons from './AttributeActionButtons.vue';

@Component({
  components: {
    DraggableIcon,
    AttributeActionButtons
  }
})
export default class GroupLevelAttributeAddressField extends Vue {
  @Prop() attributeValue!: GroupLevelAttributeValue;
  @Prop() fieldClasses!: any;
  @Prop() duplicationError!: boolean;
  @Prop() deleteAttribute!: () => void;
  @Prop() updateAttributeValue!: (attribute: GroupLevelAttributeValue) => void;
  @Prop() toggleAttributeRequire!: (
    attribute: GroupLevelAttributeValue
  ) => void;
  @Prop() editAttribute!: (attribute: GroupLevelAttributeValue) => void;

  lineOneErrorMsg: string = '';
  cityErrorMsg: string = '';
  postcodeErrorMsg: string = '';
  stateErrorMsg: string = '';
  countryErrorMsg: string = '';
  currentCountry: AddressCountry | undefined = undefined;
  currentStates: AddressState[] = [];
  selectedCountryId: number = 0; // used to determine whether to store fetched states
  isStateless: boolean = true;

  // shorthand referenced for rendering only
  building = this.attributeValueAsGLAOptionArray[0];
  lineOne = this.attributeValueAsGLAOptionArray[1];
  lineTwo = this.attributeValueAsGLAOptionArray[2];
  city = this.attributeValueAsGLAOptionArray[3];
  postcode = this.attributeValueAsGLAOptionArray[4];
  state = this.attributeValueAsGLAOptionArray[5];
  country = this.attributeValueAsGLAOptionArray[6];

  @Action('admin/getCountries')
  getAllCountries!: (addressFilter?: AddressFilterOption) => void;

  @State(({ admin }: RootState) => admin.apiState.getCountries)
  getAllCountriesApiState!: ApiState;

  @State(({ admin }: RootState) => admin.countries)
  allCountries!: AddressCountry[] | undefined;

  @Action('admin/getCountryByCountryId')
  getCountryById!: (countryId?: number) => void;

  @State(({ admin }: RootState) => admin.apiState.getCountryById)
  getCountryByIdApiState!: ApiState;

  @State(({ admin }: RootState) => admin.countryById)
  countryById!: AddressCountry | undefined;

  mounted() {
    if (this.allCountries === undefined) {
      this.getAllCountries();
    }

    if (this.statesList.length > 0) {
      this.validateRequiredFieldValues();
    }
  }

  get attributeValueAsGLAOptionArray(): GroupLevelAttributeOption[] {
    if (!this.attributeValue.value) {
      return this.attributeValue.groupLevelAttributeType
        .option as GroupLevelAttributeOption[];
    }
    return this.attributeValue.value as GroupLevelAttributeOption[];
  }

  // Computed property so changes from the value from parent component are synced
  get isRequired() {
    return this.attributeValue.groupLevelAttributeSpec.isRequired;
  }

  get buildingOption() {
    if (!this.attributeValue.value || !this.attributeValue.option) {
      return (this.attributeValue.groupLevelAttributeType
        .option as GroupLevelAttributeOption[])[0];
    }
    return (this.attributeValue.option as GroupLevelAttributeOption[])[0];
  }

  get addressLineTwoOption() {
    if (!this.attributeValue.value || !this.attributeValue.option) {
      return (this.attributeValue.groupLevelAttributeType
        .option as GroupLevelAttributeOption[])[2];
    }
    return (this.attributeValue.option as GroupLevelAttributeOption[])[2];
  }

  get countriesList(): AddressCountry[] {
    if (this.allCountries) {
      return this.allCountries.filter((country) => {
        return country.name
          .toLowerCase()
          .includes((this.country.value as string).toLowerCase());
      });
    }

    return [];
  }

  get statesList(): AddressState[] {
    if (this.currentStates.length) {
      return this.currentStates.filter((state) => {
        return state.name
          .toLowerCase()
          .includes((this.state.value as string).toLowerCase());
      });
    }

    return [];
  }

  /**
   * DO NOT convert this method into a getter.
   */
  fieldErrorMessages(): string[] {
    return [
      this.lineOneErrorMsg,
      this.cityErrorMsg,
      this.postcodeErrorMsg,
      this.stateErrorMsg,
      this.countryErrorMsg
    ];
  }

  /**
   * Check for country name when user types manually.
   * Assign corrected value (string case) if input matched
   */
  verifyCountryInput(): void {
    if (!this.allCountries) {
      this.countryErrorMsg = '';
      return;
    }

    if (this.country.value) {
      const targetCountry: AddressCountry | undefined = this.allCountries.find(
        (country) =>
          country.name.toLowerCase() ===
          (this.country.value as string).toLowerCase()
      );

      if (!targetCountry) {
        this.countryErrorMsg = 'Invalid country name.';
        return;
      }

      if (targetCountry) {
        this.country.value = targetCountry.name;
        this.selectedCountryId = targetCountry.id;
        this.getCountryById(targetCountry.id);
      }
    } else {
      if (this.attributeValue.groupLevelAttributeSpec.isRequired) {
        this.currentStates = [];
        this.countryErrorMsg = 'Country cannot be empty.';
        return;
      }
    }

    this.countryErrorMsg = '';
    return;
  }

  /**
   * Verifies manual input and update case to exact match
   */
  verifyStateInput(): void {
    if (this.isStateless) {
      this.stateErrorMsg = '';
      return;
    }

    // verify state value
    if (this.state.value && this.statesList.length > 0) {
      const targetState: AddressState | undefined = this.statesList.find(
        (state) => state.name === (this.state.value as string)
      );

      if (!targetState) {
        this.stateErrorMsg = 'Invalid state name.';
        return;
      }

      if (targetState) {
        this.state.value = targetState.name;
        this.stateErrorMsg = '';
      }
    } else {
      // state value can only be left blank when country doesn't have state
      if (
        this.attributeValue.groupLevelAttributeSpec.isRequired &&
        !this.isStateless
      ) {
        this.stateErrorMsg = 'State cannot be empty.';
        return;
      }
    }

    this.stateErrorMsg = '';

    return;
  }

  verifyLineOneInput(lineOneInput?: string): void {
    lineOneInput = lineOneInput || (this.lineOne.value as string);

    if (
      this.attributeValue.groupLevelAttributeSpec.isRequired &&
      !lineOneInput
    ) {
      this.lineOneErrorMsg = 'Line one cannot be empty.';
    } else {
      this.lineOneErrorMsg = '';
    }
  }

  verifyCityInput(cityInput?: string): void {
    cityInput = cityInput || (this.city.value as string);

    if (this.attributeValue.groupLevelAttributeSpec.isRequired && !cityInput) {
      this.cityErrorMsg = 'City cannot be empty.';
    } else {
      this.cityErrorMsg = '';
    }
  }

  verifyPostcodeInput(postcodeInput?: string): void {
    postcodeInput = postcodeInput || (this.postcode.value as string);

    if (
      this.attributeValue.groupLevelAttributeSpec.isRequired &&
      !postcodeInput
    ) {
      this.postcodeErrorMsg = 'Postcode cannot be empty.';
    } else {
      this.postcodeErrorMsg = '';
    }
  }

  updateErrorState(): boolean {
    this.attributeValue.hasFieldError =
      this.fieldErrorMessages().join('').length > 0;
    return this.attributeValue.hasFieldError;
  }

  assignCountryValue(selectedCountry: AddressCountry | null): void {
    this.country.value = selectedCountry ? selectedCountry.name : '';
    this.state.value = '';

    if (selectedCountry) {
      this.selectedCountryId = selectedCountry.id;
      this.getCountryById(selectedCountry.id);
    }
  }

  assignStateValue(selectedState: AddressState | null): void {
    this.state.value = selectedState ? selectedState.name : '';
  }

  @Watch('isRequired')
  validateRequiredFieldValues(): void {
    this.verifyLineOneInput();
    this.verifyCityInput();
    this.verifyPostcodeInput();
    this.verifyStateInput();
    this.verifyCountryInput();
  }

  @isTruthy
  @Watch('getAllCountriesApiState.error')
  @Watch('getCountryByIdApiState.error')
  addressApiErrorCallback(): void {
    Toast.open('Error fetching countries and states.');
  }

  @Watch('getAllCountriesApiState.success')
  validateCountryCallback(): void {
    this.verifyCountryInput();

    // clear state value when there's error with country name
    if (this.countryErrorMsg) {
      this.state.value = '';
      this.currentStates = [];
    }
  }

  @isTruthy
  @Watch('getCountryByIdApiState.success')
  validateStateCallback(): void {
    if (
      this.countryById &&
      (this.selectedCountryId === 0 ||
        this.selectedCountryId === this.countryById.id)
    ) {
      this.currentCountry = clone(this.countryById) as AddressCountry;
      this.currentStates = clone(this.countryById.states) as AddressState[];
      this.isStateless = this.currentStates.length === 0;
    }
  }

  @Watch('attributeValue.label')
  @Watch('lineOne.value')
  @Watch('lineTwo.value')
  @Watch('city.value')
  @Watch('postcode.value')
  @Watch('country.value')
  @Watch('state.value')
  validateAndUpdate(): void {
    this.validateRequiredFieldValues();
    this.updateAttributeValue(this.attributeValue);
  }

  @Watch('labelErrorMsg')
  @Watch('lineOneErrorMsg')
  @Watch('cityErrorMsg')
  @Watch('postcodeErrorMsg')
  @Watch('stateErrorMsg')
  @Watch('countryErrorMsg')
  checkErrorMessageCallback(): void {
    this.updateErrorState();
    this.updateAttributeValue(this.attributeValue);
  }
}
