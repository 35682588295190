import Container from '@/components/Container.vue';
import {
  CreateGroupLevelAttributeTemplateDTO,
  InsertGroupLevelAttributeValueDTO
} from '@/jbi-shared/dto/jaas-group-level-attributes.dtos';
import { StringInputOption } from '@/jbi-shared/types/form.types';
import {
  AttributeOptions,
  AttributeOptionsWithValue,
  GroupLevelAttributeValue
} from '@/jbi-shared/types/jaas-group-level-attributes.types';
import { isDefaultGroupAttribute } from '@/jbi-shared/util/group-level-attributes.util';
import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import { GroupUserAttributeWithValue } from '@/store/modules/admin/types/group-user-attribute.types';
import {
  CreateNewGroupRequestPayload,
  CreateNewGroupResponsePayload,
  ProtectedGroupTypes,
  UserAttributesStringInputOption
} from '@/store/modules/admin/types/group.types';
import { GetSignedUrlForUploadResponsePayload } from '@/store/modules/static-file/types/static-file.types';
import { RootState } from '@/store/store';
import { ApiState } from '@/store/types/general.types';
import AgreementSection from '@/views/AdminCreateGroup/components/AgreementSection.vue';
import EmailDomainForm from '@/views/AdminCreateGroup/components/EmailDomainForm.vue';
import GroupAttributeForm from '@/views/AdminCreateGroup/components/GroupAttributeForm.vue';
import GroupCreationSubmitButtons from '@/views/AdminCreateGroup/components/GroupCreationSubmitButtons.vue';
import MembersUploadSection from '@/views/AdminCreateGroup/components/MembersUploadSection.vue';
import ExistingMembersSelector from '@/views/AdminCreateSubGroup/components/ExistingMembersSelector.vue';
import { DialogProgrammatic, ToastProgrammatic } from 'buefy';
import { cloneDeep } from 'lodash';
import {
  ValidationObserver,
  ValidationObserverInstance,
  ValidationProvider
} from 'vee-validate';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  GroupUserAttributeTypeStatus,
  MyjbiGroupDetail,
  MyjbiGroupType,
  MyjbiGroupUserAttributeSpec
} from '../../../jbi-shared/types/myjbi-group.types';
import {
  hasSameAttributesAsParent,
  MemberObject,
  userAttributesArrWithValues
} from '../../../utils/group.util';

export const defaultAttributes: MyjbiGroupUserAttributeSpec[] = [
  {
    groupUserAttribute: {
      id: 1,
      name: 'Email',
      slug: 'email',
      option: null,
      status: GroupUserAttributeTypeStatus.ACTIVE,
      groupUserAttributeType: {
        id: 1,
        type: 'email',
        option: null,
        status: GroupUserAttributeTypeStatus.ACTIVE
      }
    },
    required: true,
    isDefault: true
  },
  {
    groupUserAttribute: {
      id: 2,
      name: 'First Name',
      slug: 'first-name',
      option: null,
      status: GroupUserAttributeTypeStatus.ACTIVE,
      groupUserAttributeType: {
        id: 1,
        type: 'text',
        option: null,
        status: GroupUserAttributeTypeStatus.ACTIVE
      }
    },
    required: true,
    isDefault: true
  },
  {
    groupUserAttribute: {
      id: 3,
      name: 'Last Name',
      slug: 'last-name',
      option: null,
      status: GroupUserAttributeTypeStatus.ACTIVE,
      groupUserAttributeType: {
        id: 1,
        type: 'text',
        option: null,
        status: GroupUserAttributeTypeStatus.ACTIVE
      }
    },
    required: true,
    isDefault: true
  }
];

export function generateGroupCreationMixin() {
  @Component({
    components: {
      Container,
      BaseMultiSelect,
      AgreementSection,
      ValidationObserver,
      ValidationProvider,
      GroupAttributeForm,
      GroupCreationSubmitButtons,
      MembersUploadSection,
      ExistingMembersSelector,
      EmailDomainForm
    }
  })
  class GroupCreationMixin extends Vue {
    name = '';
    types: UserAttributesStringInputOption[] = [];
    userAttributes: MyjbiGroupUserAttributeSpec[] = [];
    agreementFiles: Array<File | GetSignedUrlForUploadResponsePayload> = [];
    membersFile: File | null = null;
    membersData: MemberObject[] | null = null;
    notify = false;
    emailDomains = [];
    protectedTypes: ProtectedGroupTypes | null = null;
    isValidGroup = true;

    @Action('admin/createNewGroup')
    createNewGroup!: (
      payload: CreateNewGroupRequestPayload
    ) => Promise<CreateNewGroupResponsePayload>;

    @Action('admin/getParentGroupDetailByParentGroupId')
    getParentGroupDetailByParentGroupId!: (
      id: number
    ) => Promise<MyjbiGroupDetail>;

    @Action('admin/resetParentGroupDetailByParentGroupId')
    resetParentGroupDetailByParentGroupId!: () => void;

    @Action('admin/createGroupLevelAttributesValues')
    createGroupLevelAttributeValues!: (
      payload: InsertGroupLevelAttributeValueDTO
    ) => Promise<void>;

    @Action('admin/createGroupLevelAttributeTemplate')
    createGroupLevelAttributeTemplate!: (
      payload: CreateGroupLevelAttributeTemplateDTO
    ) => void;

    @State(
      ({ admin }: RootState) => admin.apiState.createGroupLevelAttributeTemplate
    )
    createGroupLevelAttributeTemplateApiState!: ApiState;

    @State(({ admin }: RootState) => admin.apiState.createNewGroup)
    createNewGroupApiState!: ApiState;

    @State(
      ({ admin }: RootState) => admin.apiState.createGroupLevelAttributeValues
    )
    createGroupLevelAttributeValuesApiState!: ApiState;

    @State(
      (state: RootState) => state.admin.verifyGroupLevelAttributeTemplateName
    )
    isDuplicateTemplateName!: boolean;

    currentTab: number = 0;
    selectedGroupLevelAttributeValues: GroupLevelAttributeValue[] = [];
    activeGroupLevelAttributes: GroupLevelAttributeValue[] = [];
    deleteGroupAttributes: GroupLevelAttributeValue[] = [];
    groupLevelAttributeFormHasError: boolean = true;

    saveAsTemplate: boolean = false;
    templateName: string = '';

    subGroupCreationError: boolean = false;

    get typeOptions(): StringInputOption[] {
      return Object.values(MyjbiGroupType).map((v) => ({ id: v, name: v }));
    }

    get parentGroupDetailByParentGroupId() {
      return (this.$store.state as RootState).admin
        .parentGroupDetailByParentGroupId;
    }

    get parentGroupId() {
      return +this.$route.params.parentGroupId;
    }

    get groupId() {
      return +this.$route.params.groupId;
    }

    get getParentGroupDetailByParentGroupIdLoading() {
      return (this.$store.state as RootState).admin.apiState
        .getParentGroupDetailByParentGroupId.loading;
    }

    get hasSameAttributesAsParent() {
      return hasSameAttributesAsParent(
        this.userAttributes,
        this.parentGroupDetailByParentGroupId
      );
    }

    get parentAttributes(): MyjbiGroupUserAttributeSpec[] | undefined {
      return this.parentGroupDetailByParentGroupId?.groupUserAttributeSpecs;
    }

    get parentGroupName() {
      return this.parentGroupDetailByParentGroupId?.name;
    }

    get parentGroupType() {
      return this.parentGroupDetailByParentGroupId?.types
        ? this.parentGroupDetailByParentGroupId?.types
        : [];
    }

    get parentProtectedTypes() {
      return this.parentGroupDetailByParentGroupId?.protectedTypes;
    }

    get isMemberSelectorVisible(): boolean {
      return this.hasSameAttributesAsParent && !this.subGroupCreationError;
    }

    async handleCreateGroup() {
      const userAttributesData: GroupUserAttributeWithValue[][] =
        this.membersData && this.userAttributes
          ? userAttributesArrWithValues(this.membersData, this.userAttributes)
          : [];
      const rootValidationObserver = this.$refs
        .rootValidationObserver as ValidationObserverInstance;
      if ((await rootValidationObserver.validate()) === false) {
        const message = !this.name?.trim()
          ? 'Name is required'
          : !this.userAttributes?.length
          ? 'User Attributes are required'
          : !this.membersData?.length
          ? 'Member data is required'
          : !this.types?.length
          ? 'Group type is requried'
          : 'Please fill in all the required fields';

        return DialogProgrammatic.alert({
          title: `Error`,
          message: `<p class="subtitle">${message}<p>`,
          // confirmText: 'Go Back',
          type: 'is-primary'
        });
      }
      try {
        const payload: CreateNewGroupRequestPayload = {
          name: this.name,
          attributesSpecs: this.userAttributes,
          agreements: this.agreementFiles.map((f) => ({
            fileUri: (f as GetSignedUrlForUploadResponsePayload).storageUri,
            fileName: (f as GetSignedUrlForUploadResponsePayload).fileName
          })),
          userAttributesArr: userAttributesData,
          parentPath: this.parentGroupDetailByParentGroupId?.path,
          types: this.parentGroupType.map((type) => type as MyjbiGroupType),
          notify: this.notify,
          emailDomains: [],
          protectedGroup: this.parentProtectedTypes
            ? {
                isProtected: this.parentProtectedTypes.isProtected,
                protectedGroupType: this.parentProtectedTypes.protectedType
              }
            : undefined
        };
        this.subGroupCreationError = false;

        const { id } = await this.createNewGroup(payload);

        const glaValuesPayload: InsertGroupLevelAttributeValueDTO = {
          groupId: id,
          attributeValues: this.convertToAttributeOptionsWithValue(
            this.activeGroupLevelAttributes
          )
        };

        if (this.activeGroupLevelAttributes.length) {
          await this.createGroupLevelAttributeValues(glaValuesPayload);
        }

        if (
          !this.isDuplicateTemplateName &&
          this.saveAsTemplate &&
          this.templateName
        ) {
          this.createGLATemplateConfirmation();
        }

        ToastProgrammatic.open({
          queue: true,
          type: 'is-dark',
          position: 'is-top',
          message: this.parentGroupDetailByParentGroupId
            ? `Subgroup created`
            : `Group created`
        });

        this.$router.push({
          name: 'admin-view-group',
          params: {
            groupId: String(id)
          }
        });
      } catch (error: any) {
        this.subGroupCreationError = true;
        // switch view to user attribute view on error
        this.currentTab = 0;
        ToastProgrammatic.open({
          queue: true,
          type: 'is-danger',
          position: 'is-top',
          message: error?.response?.data?.message || error,
          duration: 5000
        });
      }
    }

    /*
     * "activeGroupLevelAttributes" is an array of group level attributes with values.
     * This method converts active group level attributes into attribute options, excluding default
     * attributes. Order the non-default attributes sequentially starting from index 1.
     */
    getGroupLevelAttributesAsAttributeOptions(): AttributeOptions[] {
      return this.activeGroupLevelAttributes
        .filter((attribute: GroupLevelAttributeValue) => {
          return !isDefaultGroupAttribute(attribute);
        })
        .map(
          (nonDefaultAttributes: GroupLevelAttributeValue, index: number) => {
            return {
              groupLevelAttributeId: nonDefaultAttributes.id!,
              order: index + 1,
              isRequired:
                nonDefaultAttributes.groupLevelAttributeSpec.isRequired
            };
          }
        );
    }

    handleCreateGLATemplate() {
      this.createGroupLevelAttributeTemplate({
        templateName: this.templateName,
        attributes: this.getGroupLevelAttributesAsAttributeOptions()
      });
    }

    createGLATemplateConfirmation(): void {
      const title = 'Create New Attribute Template?';
      const content = `${this.activeGroupLevelAttributes.length} attributes (excluding default attributes) will be added as custom template. Continue?`;
      const dialogMessage = `
          <p class="buefy-dialog-title">${title}</p>
          <p class="buefy-dialog-content">${content}</p>
        `;

      this.$buefy.dialog.confirm({
        message: dialogMessage,
        confirmText: 'Save',
        onConfirm: this.handleCreateGLATemplate,
        canCancel: ['button'],
        cancelText: 'Cancel',
        onCancel: undefined
      });
    }

    convertToAttributeOptionsWithValue(
      attributes: GroupLevelAttributeValue[]
    ): AttributeOptionsWithValue[] {
      return attributes.map((attr) => ({
        groupLevelAttributeId: attr.id!, // Assertion that id exists (ensure validation)
        order: attr.groupLevelAttributeSpec.order,
        isRequired: attr.groupLevelAttributeSpec.isRequired,
        mapId: attr.mapId,
        groupLevelAttributeSpecId: attr.groupLevelAttributeSpec.id,
        status: attr.mapStatus,
        value: attr.value
      }));
    }

    public isValidGroupMember(members: MemberObject[] | null) {
      if (!members) {
        return (this.isValidGroup = true);
      }

      if (members.length > 0 && typeof members[0].email === 'string') {
        return (this.isValidGroup = true);
      }

      const checkMemberListIsValid = members.filter((user) => {
        const invalidAttribute = Object.entries(user)
          .map((attribute) => attribute[1].isValid)
          .filter((attribute) => !!attribute === false);

        if (
          invalidAttribute.findIndex((attribute) => !!attribute === false) !==
          -1
        ) {
          return invalidAttribute;
        }
      });

      this.isValidGroup = checkMemberListIsValid?.length > 0 ? false : true;
    }

    handleUpdateGroupLevelAttributeValues(
      activeValues: GroupLevelAttributeValue[],
      deleteValues: GroupLevelAttributeValue[],
      error: boolean
    ) {
      this.activeGroupLevelAttributes = activeValues;
      this.deleteGroupAttributes = deleteValues;
      this.groupLevelAttributeFormHasError = error;
    }

    templateDetails(saveAsTemplate: boolean, templateName: string) {
      this.saveAsTemplate = saveAsTemplate;
      this.templateName = templateName;
    }

    @Watch('membersData', { deep: true })
    public watchMembersData() {
      this.isValidGroupMember(this.membersData);
    }

    @Watch('userAttributes')
    onAttributeChange() {
      if (!this.parentGroupId) {
        this.membersData = null;
        this.membersFile = null;
      }
    }

    @Watch('parentGroupId', { immediate: true })
    onParentGroupIdChange() {
      if (this.parentGroupId) {
        this.getParentGroupDetailByParentGroupId(this.parentGroupId);
      } else {
        this.resetParentGroupDetailByParentGroupId();
      }
    }

    @Watch('parentAttributes', { immediate: true })
    onParentAttributesChange() {
      this.userAttributes =
        this.parentAttributes || cloneDeep(defaultAttributes);
    }
  }
  return GroupCreationMixin;
}
